<template>
  <div class="breadEval">
    <div class="breadTitle" v-if="editableTabs.length>0">
        <el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab" @tab-click="changeTab">
          <el-tab-pane
            :key="index"
            v-for="(item, index) in editableTabs"
            :label="item.name"
            :name="item.path"
          >
          </el-tab-pane>
        </el-tabs>
        <span class="line_form_item if-box">
          <el-dropdown size="small" type="primary" trigger="click" @command="handleCommand">
            <el-button type="text">操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="clear">关闭全部</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
    </div>
  </div>
</template>
<script>
  // import History from '@/utils/history.js';
  export default {
    //面包屑解决方案，
    data() {
      return {
        editableTabs:[],
        editableTabsValue:''
      };
    },
    created(){
      // this.loadChange()
    },
    methods:{
      removeTab(targetName) {
        var arr = JSON.parse(JSON.stringify(this.editableTabs)),rIndex = '',rIndex1 = '';
        for (var i = 0; i < arr.length; i++) {
          if(arr[i].path == targetName){
            rIndex = i
          }
        }
        this.$nextTick(function(){
          arr.splice(rIndex,1);
          if(arr.length>0){
            var l = arr.length;
            var activeName = arr[l-1].path;
            this.$set(this,'editableTabs',arr);
            this.$set(this,'editableTabsValue',activeName);
            // this.$router.back(-rIndex1);
            // this.$router.push({path:activeName})
            this.$parent.findRout(activeName);
            window.sessionStorage.setItem('routHistory',JSON.stringify(arr))
          }else{
            window.sessionStorage.removeItem('routHistory')
            this.$set(this,'editableTabs',arr);
            this.$set(this,'editableTabsValue','');
            this.toFrist()
          }
        })
      },
      changeTab(row){
        var tab = row.name;
        this.$parent.findRout(tab);
      },
      handleCommand(command) {
          if(command == 'clear'){
            window.sessionStorage.removeItem('routHistory')
            this.$set(this,'editableTabs',[]);
            this.$set(this,'editableTabsValue','');
            this.$nextTick(function(){
              this.toFrist()
            })
          }
      },
      toFrist(){
        var u = window.localStorage.getItem('user');
        var m = JSON.parse(u);
        var url = m.menus[0].children[0].url
        this.$parent.findRout(url);
        this.$parent.setBread();
      },
      loadChange() {
        var harr = window.sessionStorage.getItem('routHistory')
        var arr = [];
        if(harr&&harr!=''){
          arr = JSON.parse(harr)
        }
        var obj = {},arr1 = [];
        for (var z = 0; z < arr.length; z++) {
          console.log(arr[z])
          if(!obj[arr[z].path]){
            arr1.push(arr[z])
            obj[arr[z].path] = 1
          }  
        }
        this.$nextTick(function(){
          var n = this.$router.history.current.path,str=''
          for (var i = 0; i < arr1.length; i++) {
            if(arr1[i].path == n){
              str = arr1[i].path
            }
          }
          this.$set(this,'editableTabsValue',str)
          this.$set(this,'editableTabs',arr1)
          this.$parent.setBread();
        })
      }
    },
    watch: {
      $route(to, from) {
        this.loadChange()
      }
    },
    //页面挂载之后,解析路由，给出面包屑，路由里面一定要含有breadCom组件的path
    mounted: function () {
      this.loadChange()
    }
  };
</script>
<style lang="less" scoped>
  .breadEval {
    position: relative;
    font-size: 14px;
    // height: 100%;
    background: #F3F7FD;
    .breadTitle{
      background: #fff;
      position: relative;
      padding: 5px 0;
      /deep/.el-tabs{
        .el-tabs__header{
          margin: 0;
          padding-right: 60px;
        }
      }
      span.line_form_item{
        position: absolute;
        top: 0;
        bottom: auto;
        margin: auto;
        right: 0;
      }
    }
  }
</style>