<template>
    <div class="inside flex f_start">
        <div class="aslid">
            <el-input
                placeholder="输入关键字进行过滤"
                v-model="filterText">
                 <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <el-tree
                class="filter-tree"
                :data="data"
                :props="defaultProps"
                
                :filter-node-method="filterNode"
                ref="tree">
                <!-- default-expand-all -->
                <span class="custom-tree-node" slot-scope="{ node }" @click="setItem(node)">
                    <!-- <i class="line"></i> -->
                    <span>
                        <template v-if="node.data.type">
                            <label class="if-box tree-item-label" v-if="node.data.type =='are'">
                                <i class="el-icon-location-outline"></i>
                            </label>
                            <label class="if-box tree-item-label" v-else-if="node.data.type == 'village'">
                                <i class="el-icon-office-building"></i>
                            </label>
                            <label class="if-box tree-item-label" v-else-if="node.data.type =='lift'">
                                <i class="el-icon-sort"></i>
                            </label>
                        </template>
                        {{ node.label }}
                    </span>
                </span>
            </el-tree>
        </div>
        <div class="section" id="scroll_are" @scroll="scrollEvent">
            <div class="top_menu panel" :class="{'isSticky':sty}">
                <el-tag
                    v-for="(tag,i) in tags"
                    :key="tag.name"
                    :class="{'select':tag.select}" @click="setRout(tag,i)">
                    {{tag.name}}
                </el-tag>
            </div>
            <div class="data_are">
                <router-view />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'dataLayout',
    data(){
        return{
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            filterText: '',
            data: [{
                id: 1,
                label: '重庆市',
                children: [{
                        id: "1-1",
                        label: '江北区',
                        type:'are',
                        children: [{
                            id: "1-1-1",
                            label: '保利中心',
                            type:'village',
                            children:[
                                {
                                    id: "1-1-1-1",
                                    label: '3栋1#',
                                    type:'lift',
                                },{
                                    id: "1-1-1-2",
                                    label: '3栋2#',
                                    type:'lift',
                                }
                            ]
                            }, {
                            id: "1-1-2",
                            label: '大川水岸',
                            children:[
                                {
                                    id: "1-1-2-1",
                                    label: '1栋1#',
                                },{
                                    id: "1-1-2-2",
                                    label: '1栋2#',
                                }
                            ]
                        }]
                    },{
                        id: "1-2",
                        label: '巴南区',
                        type:'are',
                        children:[
                            {
                                id: "1-2-1",
                                label: '爱心家园',
                                type:'village',
                                children:[
                                    {
                                        id: "1-2-1-1",
                                        label: '3栋1#',
                                        type:'lift',
                                    },
                                ]
                            }
                        ]
                    }]
                }, 
            ],
            sty:false,
            tags: [
                { name: '概览',select:true,path:'doverview'},
                { name: '电梯档案',path:'archives'},
                { name: '动态数据',path:'syndata'},
                { name: '告警',path:'alarm'},
                { name: '工单',path:'alarm'},
                { name: '呼叫历史',path:'callhistory'},
                { name: '维保记录',path:'mtnrecord'},
                { name: '应急处置记录'},
                { name: '投诉处置记录'},
                { name: '通知消息'}
            ]
        }
    },
    created(){
        let n = this.$router.history.current.name;
        let z = null;
        this.tags.map((v,i)=>{
            if(v.path == n){
                z = i
            }
        })
        if(z||z===0){
            this.setRout(this.tags[z],z)
        }
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    methods:{
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        setItem(row){
            console.log(row);
            if(row.data.type == 'lift'){
                this.$router.push({ name: 'doverview' })
            }
        },
        scrollEvent(e){
            if(e.target.scrollTop>20){
                if(!this.sty){
                    this.$set(this,'sty',true)
                }
            }else{
                if(this.sty){
                    this.$set(this,'sty',false)
                }
            }
        },
        setRout(m,i){
            let arr = JSON.parse(JSON.stringify(this.tags));
            let item = arr[i];
            if(!item.select){
                arr.map((v,z)=>{
                    if(z!==i){
                        if(v.select){
                            delete v.select
                        }
                    }else{
                        v.select = true;
                    }
                })
                this.$set(this,'tags',arr);
                if(item.path&&item.path!=''){
                    this.$router.push({ name: item.path })
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
.inside{
    width: 100%;
    height: 100%;
    .aslid{
        width: 2.6rem;
        height: 100%;
        background-color: #333741;
        padding: .19rem .1rem;
        /deep/.el-tree{
            background: transparent;
            color: #9CA5BC;
            font-size: .14rem;
            font-family: "MicrosoftYaHei";
            letter-spacing: .01rem;
            .custom-tree-node{
                text-overflow: ellipsis;
                overflow: hidden;
                &:hover,&:active{
                    background-color: rgba(2,120,241,.5);
                }
                .el-tree-node__expand-icon,.tree-item-label{
                    margin-right: .06rem;
                }
            }
            
            .el-tree-node__content{
                &:hover,&:active{
                    background-color: transparent;
                }
            }
            .el-tree-node:focus>.el-tree-node__content{
                background-color: transparent;
            }
        }
        /deep/.el-input{
            .el-input__suffix-inner{
                .el-input__icon{
                    line-height: 36px;
                }
            }
        }
    }
    .section{
        width: 100%;
        height: 100%;
        background-color: #F6F6F6;
        overflow-y: auto;
        font-size: .18rem;
        padding: 0 .2rem .1rem;
        position: relative;
        .top_menu{
            padding: .1rem;
            overflow-x: auto;
            white-space: nowrap;
            margin-top: .1rem;
            span.el-tag{
                margin-left: .25rem;
                cursor: pointer;
                border: none;
                background-color: transparent;
                min-width: .68rem;
                text-align: center;
                color: #333333;
                height: .32rem;
                line-height: .32rem;
                font-size: .12rem;
                &:first-of-type{
                    margin-left: 0;
                }
                &.select,&:hover,&:active{
                    background-color: #F6F6F6;
                }
                &.select{
                    font-weight: 600;
                }
            }
            &.isSticky{
                position: sticky;
                top: 0;
                z-index: 1;
            }
        }
        .data_are{
            width: 100%;
            min-height: calc(100% - 0.8rem);
            // overflow-y: auto;
        }
    }
}
</style>