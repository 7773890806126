<template>
    <div id="mastLayout">
        <div class="tab_box">
            <div class="top_menu flex f_between">
                <div class="left flex">
                    <div  class="if-box">
                        <label class="logo if-box">
                            <img src="@/assets/images/logo_new.png"/>
                        </label>
                        <i>智慧电梯综合应用平台</i>
                    </div>
                </div>
                <div class="right if-box">
                     <!-- <a>
                        <label>
                            <img src="@/assets/images/kb_icon.png"/>
                        </label>
                        <i>打开看板</i>
                    </a> -->
                    <el-dropdown size="medium" trigger="click" @command="handleCommand">
                        <span class="el-dropdown-link">
                            <a>
                                <i>{{user.name+'('+user.userName+')'}}</i>
                            </a>
                            <i class="el-icon-caret-bottom el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <div class="user_card flex">
                                <label class="u_label if-box">
                                    <!-- <img :src="user.headimg" v-if="user.headimg&&user.headimg!=''"/> -->
                                    <img src="@/assets/logo.png"/>
                                </label>
                                <div class="u_text">
                                    <h5>{{user.name}}</h5>
                                    <p>{{user.userName}}</p>
                                </div>
                            </div>
                            <el-divider></el-divider>
                            <el-dropdown-item command='out' >退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown size="medium" trigger="click" @command="handleRole">
                        <span class="el-dropdown-link">
                            <a>
                                {{roleInfo.name}}
                            </a>
                            <i class="el-icon-caret-bottom el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command='item.id' v-for="(item,index) in roleList" :key="index">{{item.name}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <a>
                        <!-- :value="0" -->
                    <el-badge is-dot :hidden="showNotice">
                        <template v-if="showNotice">
                            <el-popover
                                v-model="showNoticeHistory"
                                width="360"
                                @show="getNotics">
                                <div class="notice_list">
                                    <div class="item" v-for="(item,index) in noticeHistorys" :key="index" :class="{'no':!item.read}" @click="goItem(item)">
                                        <h5>{{item.title}}</h5>
                                        <p>{{item.updTime}}</p>
                                    </div>
                                </div>
                                <label class="f_r" slot="reference">
                                    <img src="@/assets/images/news.png"/>
                                </label>
                            </el-popover>
                        </template>
                        <template v-else>
                            <label class="f_r" @click="dialogVisible = true">
                                <img src="@/assets/images/news.png"/>
                            </label>
                        </template>
                    </el-badge>
                </a>
                </div>
            </div>
            <!-- <div class="top_tab flex">
                <label class="if-box" v-for="item in menuList" :key="item.id" v-bind:class="{'select':$store.getters.getMenuType === item.id}" @click="setMenu(item)">{{item.name}}</label>
            </div> -->
        </div>
        <div class="main_box">
            <router-view ref="inside" />
        </div>
        <el-dialog
            :title="noticeDialogForm.title"
            :visible.sync="dialogVisible"
            width="630px"
            @close="refreeNotice">
            <div class="notice_box" style="height:300px">
                {{noticeDialogForm.content}}
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="setNotice(2)">取 消</el-button>
                <el-button type="primary" @click="setNotice(1)">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
// import { Modal } from 'ant-design-vue'
// import { AvatarList } from '@/components'
// const AvatarListItem = AvatarList.AvatarItem
import { outLogin,getAuth } from '@/api/interface.js';//引入文件
import {genKeyPair,decrypt} from '@/utils/index.js';
import { getData,postData} from '@/api/mc.js';
export default {
    name: 'basicLayout',
    data(){
        return{
            menuList:[],
            user:'',
            roleList:[],
            roleInfo:{
                role:'',
                name:'',
                type:''
            },
            showNotice:true,
            noticeMain:'',
            noticeDialogForm:'',
            dialogVisible:false,
            noticeHistorys:[],
            showNoticeHistory:false
        }
    },
    components: {
        // AvatarList,
        // AvatarListItem
    },
    computed:{
        // menuList(){
        //     let m = JSON.parse(window.localStorage.getItem('user'))
        //     console.log(m);
        //     return m.menus
        // }
    },
    created(){
        var user = window.localStorage.getItem('user');
        if(user){
            if(!JSON.parse(user).hasGetCorpAuth){
                //维保单位、维保经理、 物业单位、 物业经理
                //cate:1.维保公司2.物业公司3质监局4.维保员5.维保经理6.安管员7.物业经理8.运维管理0.普通角色
                if(user.cate == 1||user.cate ==5|| user.cate == 2||user.cate == 7){
                    this.corpAuth()
                }
            }
        }
        this.setMenus();
        this.getMyNotice()
    },
    methods:{
        corpAuth(){
            var temp = {
                face:'getCorpAuth',
                data:{}
            }
            getData(temp).then(res=>{
                var arr = res.data;
                if(arr.length>0){
                    var m = arr[0]
                    this.$confirm(m.label, '提示', {
                        showClose:false,
                        showCancelButton:false,
                        confirmButtonText: '前往完善',
                        type: 'warning',
                        closeOnClickModal:false,
                        closeOnPressEscape:false,
                        closeOnHashChange:false,
                        callback: action => {
                            if (action === 'confirm') {
                                if(m.value == 1){
                                    // this.$router.push({ path: '/devops/certificate_mng'})
                                    this.$refs['inside'].setRout(87,'')
                                }else{
                                    this.$refs['inside'].setRout(88,'')
                                    // this.$router.push({ path: '/devops/property_respect'})
                                }
                            }
                        }
                    })
                }else{
                    var user = JSON.parse(window.localStorage.getItem('user'));
                    user.hasGetCorpAuth = true;//已经判断了授权书等相关权限
                    window.localStorage.setItem('user',JSON.stringify(user));
                }
            }) 
        },
        setMenus(){
            let u = window.localStorage.getItem('user');
            if(u&&u!=''){
                var m = JSON.parse(u);
                this.$set(this,'user',m)
                if(m.menus&&m.menus.length>0&&m.menus[0].children){
                    this.$set(this,'menuList',m.menus[0].children)
                }
                if(m.roleList&&m.roleList.length>0){
                    var ar = m.roleList;
                    var ri = m.cacheCorp.role,tmp = {}
                    for (var i = 0; i < ar.length; i++) {
                        if(ri == ar[i].id){
                            tmp.role = ar[i].id;
                            tmp.name = ar[i].name;
                            tmp.type = ar[i].type;
                        }
                    }
                    this.$set(this,'roleInfo',tmp)
                    this.$set(this,'roleList',m.roleList);
                    this.vaildRole()
                }
            }else{
                this.$router.push({ name: 'login' })
            }
        },
        handleCommand(command){
            if(command == 'out'){
                this.handleLogout();
            }
        },
        handleRole(id){
            //user=>cate:1.维保公司2.物业公司3质监局4.维保员5.维保经理6.安管员7.物业经理8.运维管理0.普通角色
            var _this = this
            if(_this.roleInfo.role!=id){
                var temp = {
                    face:'changeRole',
                    data:{
                        id:id
                    }
                }
                postData(temp).then(res=>{
                    if(res.success){
                        window.sessionStorage.clear();
                        window.localStorage.setItem('user',JSON.stringify(res.data));
                        _this.$nextTick(function(){
                            _this.setMenus()
                            // var url = res.data.menus[0].children[0].url
                            // _this.$refs['inside'].findRout(url);
                            _this.$refs['inside'].init()
                            location.reload()
                        })
                    }
                })
            }
        },
        handleLogout(){
            this.$confirm('提示', '是否退出当前用户', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'success',
                callback: action => {
                if (action === 'confirm') {
                    this.outIng();
                }
                else {
                    console.log('按下 取消')
                }
                }
            })
        },
        outIng(){
            let u = JSON.parse(window.localStorage.getItem('user'));
            let data = {
                userName:u.userName,
            }
            outLogin(data,'outlogin').then(res=>{
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: res.success?'success':'error'
                });
                if(res.code == 200){
                    window.sessionStorage.clear();
                    window.localStorage.clear();
                    this.$nextTick(function(){
                        this.$router.push({ name: 'login' })
                    })
                }
            })
        },
        setMenu(row){
            // if(this.$store.getters.getMenuType!==row.id){
            //     this.$store.commit('setMenuType', row.id)
            // }
        },
        getPerson(){
            let data = {
                admin:true,
            }
            getAuth(data,'authPage').then(res=>{
                console.log(res);
            })
        },
        /**
         * 验证角色对应的认证
         */
        vaildRole(){
            var role = this.roleInfo;
            if('1,2,4,5,6,7'.indexOf(role.type)>-1){
                //验证是否上传认证信息
            }
        },
        getMyNotice(){
            var temp = {
                face:'getMyUnreadNotice',
                data:{}
            }
            getData(temp).then(res=>{
                if(res.success){
                    var min = res.data;
                    if(min&&min.status&&min.status == 1){
                        this.showNotice = false;
                        this.noticeMain = min;
                        var title=JSON.parse(JSON.stringify(min.title)),cont=JSON.parse(JSON.stringify(min.content));
                        if(title.length>10){
                            title = title.substring(0,10)+'...';
                        }
                        if(cont.length>10){
                            cont = cont.substring(0,25)+'...';
                        }
                        var tmp = {
                            title:title,
                            content:cont
                        }
                        this.noticeDialogForm = tmp;
                    }
                }
            })
        },
        refreeNotice(){
            this.noticeDialogForm = '';
            this.noticeMain = '';
        },
        goItem(row){
            this.showNoticeHistory = false;
            this.noticeMain = row;
            this.setNotice(1)
        },
        setNotice(t){
            console.log(t)
            if(t == 1){
                //跳转通知详情
                let editUrl = this.$router.resolve({ path:'/noticeInfo', query: { id: this.noticeMain.id}})
                window.open(editUrl.href, '_blank')
            }
            var temp = {
                face:'readNoticeOne',
                data:{
                    id:this.noticeMain.id
                }
            }
            postData(temp).then(res=>{
                if(res.success){
                    this.showNotice=true;
                    this.noticeMain='';
                    this.dialogVisible = false;
                }
            })
        },
        getNotics(){
            var temp = {
                face:'getNoticeHistorys',
                data:{
                    pageNum:1,
                    read:0,//是否未读(0全部1未读2已读)
                }
            }
            getData(temp).then(res=>{
                if(res.success){
                    this.noticeHistorys = res.data
                }
            })
        }
    }
}
</script>
<style scoped lang="less">
#mastLayout{
    // max-width: 19.2rem;
    width: 100%;
    margin: auto;
    height: 100%;
    min-width: 9.6rem;
    .tab_box{
        .top_menu{
            width: 100%;
            height: .58rem;
            padding: 0 .2rem;
            box-shadow: 0px 2px 4px 1px rgba(106,101,101,0.16);
            .left{
                .logo{
                    width: .36rem;
                    height: .36rem;
                }
                i{
                    font-size: .26rem;
                    font-family: "PingFang SC";
                    font-weight: bold;
                    color: #333333;
                }
            }
            .right{
                a{
                    display: inline-flex;display: -webkit-inline-flex;align-items: center;justify-content: center;
                    margin: 0 .095rem;
                    cursor: pointer;
                    label{
                        display: inline-flex;
                        display: -webkit-inline-flex;
                        align-items: center;
                        justify-content: center;
                        width: .18rem;
                        height: .18rem;
                        margin-right: 0.09rem;
                        b{
                            position: absolute;
                            top: -.08rem;
                            right: -.08rem;
                            width: .16rem;
                            height: .16rem;
                            background: #FF0000;
                            border-radius: 50%;
                            font-size: .1rem;
                            font-weight: 400;
                            color: #F6F6F6;
                        }
                    }
                    .el-badge{
                        height: .18rem;
                        label{
                            margin-right: 0;
                        }
                    }
                    i{
                        font-size: .14rem;
                        font-family: "PingFang SC";
                        font-weight: 400;
                        color: #333333;
                    }
                }
                i.line{
                    display: inline-block;
                    width: 0px;
                    height: .22rem;
                    border: 1px solid #707070;
                    margin: 0 .225rem;
                }
                em{
                    width: 0px;
                    height: 0px;
                    border-left: .08rem solid transparent;
                    border-right: .08rem solid transparent;
                    border-top: .08rem solid #666666;
                    position: relative;
                    bottom: -.05rem;
                    margin-left: .095rem;
                }
            }
        }
        .top_tab{
            width: 100%;
            height: .6rem;
            background-color: #0278F1;
            padding: 0 .3rem;
            label{
                height: 100%;
                padding: 0 .3rem;
                font-size: .18rem;
                font-family: 'PingFang SC';
                font-weight: 400;
                color: #FFFFFF;
            }
            label:hover,label.select{
                background-color: #489EF0;
            }
        }
    }
    .main_box{
        width: 100%;
        height: calc(100% - 58px);
    }
}
.user_card{
    padding: 0 .3rem;
    .u_label{
        width: .8rem;
        height: .8rem;
    }
    .u_text{
        width: 1.75rem;
        padding-left: .25rem;
        font-size: .14rem;
    }
}
/deep/.test-pop{
    .el-popconfirm__main{
        line-height: 50px;
    }
}
.notice_list{
    min-height: 300px;
    padding-left: 10px;
    .item{
        width: 100%;
        padding: 12px 0;
        cursor: pointer;
        padding-left: 10px;
        position: relative;
        &:hover{
            background-color: #e6f7ff;
        }
        &::before{
            content: none;
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 12px;
            background-color: #FF0000;
            position: absolute;
            top: 5px;
            left: -5px;
        }
        &.no::before{
            content: '';
        }
        h5{
            font-size: 14px;
            font-weight: 400;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: rgba(0,0,0,.85);
            margin-bottom: 8px;
        }
        p{
            font-size: 14px;
            color: rgba(0,0,0,.45);
        }
    }
}
</style>
