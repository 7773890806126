import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuType:'',
    menus:[],
    asrKey:'',//asr公私钥
    user:"",//用户信息
    menuPromise:null,//菜单管理权限
    setParms:'',//页面传参
  },
  getters:{
    getMenuType: state => state.menuType,
    getMenus: state => state.menus,
    getAsrKey:state => state.asrKey,
    getUser:state => state.user,
    getMenuPromise:state => state.menuPromise,
  },
  mutations: {
    setMenuType(state,menuType){
      state.menuType = menuType
    },
    setMenus(state,menus){
      state.menus = menus
    },
    setAsrRublicKey(state,asrKey){
      state.asrKey = asrKey
    },
    setUser(state,user){
      state.user = user
    },
    setmenuPromise(state,menuPromise){
      state.menuPromise = menuPromise
    },
    setSetParms(state,setParms){
      state.setParms = setParms
    }
  },
  actions: {
  },
  modules: {
  }
})
