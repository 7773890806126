import Vue  from 'vue'

import JSEncrypt  from 'jsencrypt'
//只能在当页使用，如果需要跨页需要 需要将密钥对保存到缓存
const  thisKeyPair =  new JSEncrypt({default_key_size: 1024});
//生成密钥对(公钥和私钥)
export  function genKeyPair() {
    let genKeyPair = {};
    //获取私钥

    let privateKey = thisKeyPair.getPrivateKey();
    
    genKeyPair.privateKey= privateKey.replace("-----BEGIN RSA PRIVATE KEY-----", "").
            replace("-----END RSA PRIVATE KEY-----", "").replace("(\\r\\n|\\n|\\n\\r)","").trim();
    //获取公钥
    let publicKey= thisKeyPair.getPublicKey();
    genKeyPair.publicKey=publicKey.replace("-----BEGIN PUBLIC KEY-----", "").
            replace("-----END PUBLIC KEY-----", "").
            replace("(\\r\\n|\\n|\\n\\r)","").trim();

   thisKeyPair.setPublicKey(publicKey);
   thisKeyPair.setPrivateKey(privateKey);
    return genKeyPair;
}

//公钥加密
export function encrypt(plaintext, publicKey) {
    if (plaintext instanceof Object) {
        //1、JSON.stringify
        plaintext = JSON.stringify(plaintext)
    } 
    publicKey && thisKeyPair.setPublicKey(publicKey);
    return thisKeyPair.encrypt(plaintext);
}

//私钥解密
export function decrypt(ciphertext, privateKey) {
    privateKey && thisKeyPair.setPrivateKey(privateKey);
    let decString = thisKeyPair.decrypt(ciphertext);
    if(decString.charAt(0) === "{" || decString.charAt(0) === "[" ){
        //JSON.parse
        decString = JSON.parse(decString);
    }
    return decString;
}
//rgba转16进制 var myHex = hexify('rgba(12,28,57,0.1)'); 
function hexify(color) {
    var values = color
        .replace(/rgba?\(/, '')
        .replace(/\)/, '')
        .replace(/[\s+]/g, '')
        .split(',');
    var a = parseFloat(values[3] || 1),
        r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
        g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
        b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
    return "#" +
        ("0" + r.toString(16)).slice(-2) +
        ("0" + g.toString(16)).slice(-2) +
        ("0" + b.toString(16)).slice(-2);
}
//数组字符串转回数组
export function parsString(str){
    var right = null,parent = null,key='',value='';
    for (var i = 0; i < str.length; i++) {
        var letter = str[i]
        if(letter == '['){
            right = ']';
            parent = new Array();
        }else if(letter == ']'){
            if(right===letter&&value ){
                parent.push(value);
                value = ''
                right = null;
            }
        }else if(letter == ','){
            if(right === ']'){
                parent.push(value);
                value = ''
            }
        }else {
            if(letter&&letter!==' '){
                value+=letter
            }
        }
    }
    return parent
}