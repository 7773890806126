<template>
    <div class="table" :class="{'border':tableOptions.basicBorder,'large':tableOptions.dialogTable,'allTable':!tableOptions.showPage}">
        <!-- 表单查询 -->
        <div class="filter_are" v-if="tableOptions.searchcolumns.length>0">
            <el-form :inline="true" class="demo-form-inline" @submit.native.prevent>
                <div class="top flex f_between f_start">
                    <div class="filter_nav">
                        <template v-for="(item,index) in tableOptions.searchcolumns" >
                            <span class="line_form_item if-box" :key="item.prop" v-if="index<3">
                                <el-form-item :prop="item.prop" :label="item.searchcustom?'':item.label" :label-width="item.labelWidth">
                                    <template slot-scope="scope">
                                        <template v-if="item.searchcustom">
                                            <slot :name="item.prop" :data="scope"></slot>
                                        </template>
                                        <template v-else>
                                            <template v-if="item.type == 'select'">
                                                <el-select v-model="searchForm[item.prop]" :placeholder="item.placeholder?item.placeholder:'请选择'" :clearable="item.noclearable?false:true" @change="pageRefree">
                                                    <template v-for="opitem in item.column">
                                                        <template v-if="item.propformat">
                                                            <el-option :label="opitem[item.propformat.label]" :value="opitem[item.propformat.value]" :key="opitem[item.propformat.value]"></el-option>
                                                        </template>
                                                        <template v-else>
                                                            <el-option :label="opitem.label" :value="opitem.value" :key="opitem.value"></el-option>
                                                        </template>
                                                    </template>
                                                </el-select>
                                            </template>
                                            <template v-else-if="item.type == 'switch'">
                                                <el-switch
                                                    v-model="searchForm[item.prop]"
                                                    :active-text="item.activeText"
                                                    :inactive-text="item.inactiveText">
                                                </el-switch>
                                            </template>
                                            <template v-else-if="item.type == 'checkbox-group'">
                                                <el-checkbox-group v-model="searchForm[item.prop]">
                                                    <template v-for="checkitem in item.column">
                                                        <el-checkbox :label="checkitem.label" :name="item.prop" :key="checkitem.label"></el-checkbox>
                                                    </template>
                                                </el-checkbox-group>
                                            </template>
                                            <template v-else-if="item.type == 'checkbox'">
                                                <el-checkbox
                                                    v-model="searchForm[item.prop]"
                                                    :label="item.text"
                                                    :true-label="item.trueLabel?item.trueLabel:'true'"
                                                    :false-label="item.falseLabel?item.falseLabel:'false'">
                                                </el-checkbox>
                                            </template>
                                            <template v-else-if="item.type == 'radio-group'">
                                                <el-radio-group v-model="searchForm[item.prop]">
                                                    <template v-for="radioitem in item.column">
                                                        <el-radio :label="radioitem.label" :name="item.prop" :key="radioitem.label"></el-radio>
                                                    </template>
                                                </el-radio-group>
                                            </template>
                                            <template v-else-if="item.searchType == 'time'">
                                                <el-time-select
                                                    v-model="searchForm[item.prop]"
                                                    placeholder="选择时间"
                                                    :value-format="item.valueFormat?item.valueFormat:''"
                                                    :picker-options="item.pickerOptions?item.pickerOptions:''"
                                                    clearable>
                                                </el-time-select>
                                            </template>
                                            <template v-else-if="item.searchType == 'timerange'">
                                                <el-time-picker
                                                    is-range
                                                    v-model="searchForm[item.prop]"
                                                    range-separator="至"
                                                    start-placeholder="开始时间"
                                                    end-placeholder="结束时间"
                                                    placeholder="选择时间范围"
                                                    :value-format="item.valueFormat?item.valueFormat:''"
                                                    :picker-options="item.pickerOptions?item.pickerOptions:''"
                                                    clearable>
                                                </el-time-picker>
                                            </template>
                                            <template v-else-if="item.searchType == 'date'">
                                                <el-date-picker
                                                    v-model="searchForm[item.prop]"
                                                    type="date"
                                                    placeholder="选择日期"
                                                    :value-format="item.valueFormat?item.valueFormat:''"
                                                    :picker-options="item.pickerOptions?item.pickerOptions:''"
                                                    clearable>
                                                </el-date-picker>
                                            </template>
                                            <template v-else-if="item.searchType == 'daterange'">
                                                <el-date-picker
                                                    v-model="searchForm[item.prop]"
                                                    type="daterange"
                                                    range-separator="至"
                                                    start-placeholder="开始日期"
                                                    end-placeholder="结束日期"
                                                    :value-format="item.valueFormat?item.valueFormat:''"
                                                    :picker-options="item.pickerOptions?item.pickerOptions:''"
                                                    clearable>
                                                </el-date-picker>
                                            </template>
                                            <template v-else-if="item.searchType == 'datetime'">
                                                <el-date-picker
                                                    v-model="searchForm[item.prop]"
                                                    type="datetime"
                                                    placeholder="选择日期时间"
                                                    :value-format="item.valueFormat?item.valueFormat:''"
                                                    :picker-options="item.pickerOptions?item.pickerOptions:''"
                                                    clearable>
                                                </el-date-picker>
                                            </template>
                                            <template v-else-if="item.searchType == 'datetimerange'">
                                                <el-date-picker
                                                    v-model="searchForm[item.prop]"
                                                    type="datetimerange"
                                                    range-separator="至"
                                                    start-placeholder="开始日期"
                                                    end-placeholder="结束日期"
                                                    :value-format="item.valueFormat?item.valueFormat:''"
                                                    :picker-options="item.pickerOptions?item.pickerOptions:''"
                                                    :default-time="['00:00:00', '23:59:59']"
                                                    clearable>
                                                    <!-- :default-value="item.defaultValue?item.defaultValue:null" -->
                                                </el-date-picker>
                                            </template>
                                            <template v-else-if="item.type == 'textarea'">
                                                <el-input type="textarea" v-model="searchForm[item.prop]" :placeholder="'请输入'+(item.placeholder?item.placeholder:item.label)" clearable maxlength="100" show-word-limit></el-input>
                                            </template>
                                            <template v-else>
                                                <el-input v-model="searchForm[item.prop]" :placeholder="'请输入'+(item.placeholder?item.placeholder:item.label)" clearable maxlength="100" show-word-limit></el-input>
                                            </template>
                                        </template>
                                    </template>
                                </el-form-item>
                            </span>
                        </template>
                        <div class="if-box _nav_sys">
                            <span class="line_form_item if-box" v-if="tableOptions.searchcolumns.length>3">
                                <el-button type="success" size="small" plain @click="shetFilternav">{{showFilter_coll?'收起':'展开'}}</el-button>
                            </span>
                            <span class="line_form_item if-box" v-if="tableOptions.searchcolumns.length>0">
                                <el-dropdown split-button size="small" type="primary" trigger="click" @click="onSearch" @command="handleCommand">
                                    <i class="el-icon-search"></i>搜索
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="clear">清空</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </span>
                            <slot name="search_right"></slot>
                        </div>
                    </div>
                </div>
                <div class="flex f_warp _nav_collapse" v-if="showFilter_coll">
                    <template v-for="(item,index) in tableOptions.searchcolumns" >
                        <span class="line_form_item if-box" :key="index" v-if="index>2">
                            <el-form-item :prop="item.prop" :label="item.searchcustom?'':item.label" :label-width="item.labelWidth">
                                <template slot-scope="scope">
                                    <template v-if="item.searchcustom">
                                        <slot :name="item.prop" :data="scope"></slot>
                                    </template>
                                    <template v-else>
                                        <template v-if="item.type == 'select'">
                                            <el-select v-model="searchForm[item.prop]" :placeholder="item.placeholder?item.placeholder:'请选择'" :clearable="item.noclearable?false:true" @change="pageRefree">
                                                <template v-for="opitem in item.column">
                                                    <template v-if="item.propformat">
                                                        <el-option :label="opitem[item.propformat.label]" :value="opitem[item.propformat.value]" :key="opitem[item.propformat.value]"></el-option>
                                                    </template>
                                                    <template v-else>
                                                        <el-option :label="opitem.label" :value="opitem.value" :key="opitem.value"></el-option>
                                                    </template>
                                                </template>
                                            </el-select>
                                        </template>
                                        <template v-else-if="item.type == 'switch'">
                                            <el-switch
                                                v-model="searchForm[item.prop]"
                                                :active-text="item.activeText"
                                                :inactive-text="item.inactiveText">
                                            </el-switch>
                                        </template>
                                        <template v-else-if="item.type == 'checkbox-group'">
                                            <el-checkbox-group v-model="searchForm[item.prop]">
                                                <template v-for="checkitem in item.column">
                                                    <el-checkbox :label="checkitem.label" :name="item.prop" :key="checkitem.label"></el-checkbox>
                                                </template>
                                            </el-checkbox-group>
                                        </template>
                                        <template v-else-if="item.type == 'checkbox'">
                                            <el-checkbox
                                                v-model="searchForm[item.prop]"
                                                :label="item.text"
                                                :true-label="item.trueLabel?item.trueLabel:'true'"
                                                :false-label="item.falseLabel?item.falseLabel:'false'">
                                            </el-checkbox>
                                        </template>
                                        <template v-else-if="item.type == 'radio-group'">
                                            <el-radio-group v-model="searchForm[item.prop]">
                                                <template v-for="radioitem in item.column">
                                                    <el-radio :label="radioitem.label" :name="item.prop" :key="radioitem.label"></el-radio>
                                                </template>
                                            </el-radio-group>
                                        </template>
                                        <template v-else-if="item.searchType == 'time'">
                                            <el-time-select
                                                v-model="searchForm[item.prop]"
                                                placeholder="选择时间"
                                                :value-format="item.valueFormat?item.valueFormat:''"
                                                :picker-options="item.pickerOptions?item.pickerOptions:''"
                                                clearable>
                                            </el-time-select>
                                        </template>
                                        <template v-else-if="item.searchType == 'timerange'">
                                            <el-time-picker
                                                is-range
                                                v-model="searchForm[item.prop]"
                                                range-separator="至"
                                                start-placeholder="开始时间"
                                                end-placeholder="结束时间"
                                                placeholder="选择时间范围"
                                                :value-format="item.valueFormat?item.valueFormat:''"
                                                :picker-options="item.pickerOptions?item.pickerOptions:''"
                                                clearable>
                                            </el-time-picker>
                                        </template>
                                        <template v-else-if="item.searchType == 'date'">
                                            <el-date-picker
                                                v-model="searchForm[item.prop]"
                                                type="date"
                                                placeholder="选择日期"
                                                :value-format="item.valueFormat?item.valueFormat:''"
                                                :picker-options="item.pickerOptions?item.pickerOptions:''"
                                                clearable>
                                            </el-date-picker>
                                        </template>
                                        <template v-else-if="item.searchType == 'daterange'">
                                            <el-date-picker
                                                v-model="searchForm[item.prop]"
                                                type="daterange"
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期"
                                                :value-format="item.valueFormat?item.valueFormat:''"
                                                :picker-options="item.pickerOptions?item.pickerOptions:''"
                                                clearable>
                                            </el-date-picker>
                                        </template>
                                        <template v-else-if="item.searchType == 'datetime'">
                                            <el-date-picker
                                                v-model="searchForm[item.prop]"
                                                type="datetime"
                                                placeholder="选择日期时间"
                                                :value-format="item.valueFormat?item.valueFormat:''"
                                                :picker-options="item.pickerOptions?item.pickerOptions:''"
                                                clearable>
                                            </el-date-picker>
                                        </template>
                                        <template v-else-if="item.searchType == 'datetimerange'">
                                            <el-date-picker
                                                v-model="searchForm[item.prop]"
                                                type="datetimerange"
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期"
                                                :value-format="item.valueFormat?item.valueFormat:''"
                                                :picker-options="item.pickerOptions?item.pickerOptions:''"
                                                :default-time="['00:00:00', '23:59:59']"
                                                clearable>
                                                <!-- :default-value="item.defaultValue?item.defaultValue:''" -->
                                            </el-date-picker>
                                        </template>
                                        <template v-else-if="item.type == 'textarea'">
                                            <el-input type="textarea" v-model="searchForm[item.prop]" :placeholder="'请输入'+(item.placeholder?item.placeholder:item.label)" clearable maxlength="100" show-word-limit></el-input>
                                        </template>
                                        <template v-else>
                                            <el-input v-model="searchForm[item.prop]" :placeholder="'请输入'+(item.placeholder?item.placeholder:item.label)" clearable maxlength="100" show-word-limit></el-input>
                                        </template>
                                    </template>
                                </template>
                            </el-form-item>
                        </span>
                    </template>
                    <slot name="search_sys"></slot>
                </div>
            </el-form>
        </div>
        <!--表单顶部操作组-->
        <div class="table_head flex f_between">
            <div class="sys_head_tab">
                <span class="pbt_15" v-if="tableOptions.addShow">
                    <el-button type="primary" size="small" @click="addvis = true">新增</el-button>
                </span>
                <slot name="left_sys"></slot>
            </div>
            <div class="sys_head_tab" v-if="tableOptions.addShow">
                <span class="pbt_15">
                    <el-popover
                        placement="top"
                        width="200"
                        trigger="click"
                    >
                    <el-button type="primary" slot="reference" size="small" icon="el-icon-paperclip" circle></el-button>
                    <el-checkbox-group @change="setLabel" v-model="checkedCities">
                        <template v-for="item in tableOptions.columns">
                            <el-checkbox :label="item.label" :checked="!item.hide" :key="item.prop" ></el-checkbox>
                        </template>
                    </el-checkbox-group>
                    </el-popover>
                </span>
                <span class="pbt_15">
                    <el-button type="primary" size="small" icon="el-icon-refresh-left" circle @click="berefree"></el-button>
                </span>
            </div>
        </div>
        <div class="table-main" :class="tableOptions.tablePanel?'border':''">
            <div class="table_body page-component__scroll">
                <!-- :row-key="'index'" -->
                <el-table
                ref="basicTable"
                :data="tableData"
                v-loading="loading"
                @selection-change="handleSelectionChange"
                :show-header="tableOptions.showHeader"
                height="100%"
                :max-height="tableOptions.mtHeight"
                :stripe="tableOptions.stripe"
                :border="tableOptions.border"
                :row-class-name="tableOptions.showRowClassName?tableRowClassName:''"
                style="width: 100%"
                @sort-change="handleSortChange"
                :show-summary="tableOptions.showSummary"
                row-key="id">
                <!-- :height="tableOptions.tHeight" -->
                <!-- :max-height="tableOptions.mtHeight" -->
                    <el-table-column type="selection" width="55" v-if="tableOptions.selection" :reserve-selection="true"></el-table-column>
                    <el-table-column label="序号" type="index" align="center" v-if="tableOptions.showindex"></el-table-column>
                    <template v-for="(item,index) in tableOptions.columns" >
                        <template v-if="!item.hide">
                            <template v-if="tableOptions.hasFilters">
                                <el-table-column
                                    :key="index"
                                    :prop="item.prop"
                                    :label="item.label"
                                    :width="item.width"
                                    :min-width="item.minWidth"
                                    :formatter="item.format?setFormat:null"
                                    :class="item.customClassName"
                                    :align="item.align"
                                    :filters="item.filters"
                                    :filter-method="item.filterMethod"
                                    filter-placement="bottom-end"
                                    :sortable="item.sort">
                                    <template slot-scope="scope">
                                        <template v-if="item.type == 'select'||item.type == 'switch'">
                                            <template v-if="item.custom">
                                                <slot :name="item.prop" :data="scope"></slot>
                                            </template>
                                            <template v-else>
                                                {{scope.row[item.prop]|formatSelect(...item)}}
                                            </template>
                                        </template>
                                        <template v-else-if="item.type == 'formatTime'">
                                            <template v-if="item.custom">
                                                <slot :name="item.prop" :data="scope"></slot>
                                            </template>
                                            <template v-else>
                                                {{formatTime(scope.row[item.prop])}}
                                            </template>
                                        </template>
                                        <template v-else>
                                            <template v-if="item.custom">
                                                <slot :name="item.prop" :data="scope"></slot>
                                            </template>
                                            <template v-else>{{scope.row[item.prop]}}</template>
                                        </template>
                                    </template>
                                </el-table-column>
                            </template>
                            <template v-else>
                                <el-table-column
                                    :key="index"
                                    :prop="item.prop"
                                    :label="item.label"
                                    :width="item.width"
                                    :min-width="item.minWidth"
                                    :formatter="item.format?setFormat:null"
                                    :class="item.customClassName"
                                    :align="item.align"
                                    :sortable="item.sort">
                                    <template slot-scope="scope">
                                        <template v-if="item.type == 'select'||item.type == 'switch'">
                                            <template v-if="item.custom">
                                                <slot :name="item.prop" :data="scope"></slot>
                                            </template>
                                            <template v-else>
                                                {{scope.row[item.prop]|formatSelect(...item)}}
                                            </template>
                                        </template>
                                        <template v-else-if="item.type == 'formatTime'">
                                            <template v-if="item.custom">
                                                <slot :name="item.prop" :data="scope"></slot>
                                            </template>
                                            <template v-else>
                                                {{formatTime(scope.row[item.prop])}}
                                            </template>
                                        </template>
                                        <template v-else>
                                            <template v-if="item.custom">
                                                <slot :name="item.prop" :data="scope"></slot>
                                            </template>
                                            <template v-else>{{scope.row[item.prop]}}</template>
                                        </template>
                                    </template>
                                </el-table-column>
                            </template>
                        </template>
                    </template>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        :width="tableOptions.menuWidth"
                        v-if="tableOptions.showMenu"
                        >
                        <template slot-scope="scope">
                            <el-button type="primary" size="small" @click="handleEdit(scope.row,scope.$index)" v-if="tableOptions.editBtn">编辑</el-button>
                            <el-button type="danger" size="small" @click="handleDel(scope.row,scope.$index)" v-if="tableOptions.delBtn">删除</el-button>
                            <slot name="right_scope" :row="scope" ></slot>
                        </template>
                    </el-table-column>
                </el-table>
                <label class="if-box table_sys_right" v-if="tableOptions.showDraw">
                    <el-button type="primary" circle icon="el-icon-setting" @click="drawer = true"></el-button>
                </label>
            </div>
            <div class="pageFooter flex f_end" v-if="tableOptions.showPage">
                <slot name="pageSys"></slot>
                <div v-if="pageData">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageData.page?pageData.page:1"
                        :page-sizes="pageData.sizes?pageData.sizes:[10,20, 50, 100,]"
                        :page-size="pageData.pageSize?pageData.pageSize:0"
                        layout="total, sizes,prev, pager, next, jumper"
                        :total="pageData.total?pageData.total:0"
                        >
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 新增弹框 -->
        <el-dialog title="新增" :visible.sync="addvis" :close="handleClose" center="center" :close-on-click-modal="false">
            <el-form :model="form" ref="ruleForm" :label-width="tableOptions.labelWidth?tableOptions.labelWidth:''" :rules="tableOptions.rules">
                <template v-for="item in tableOptions.columns" >
                    <el-form-item :key="item.prop" :prop="item.prop" :label="item.label" v-if="!item.addHide">
                        <template v-if="item.type == 'select'">
                            <el-select v-model="form[item.prop]">
                                <template v-for="opitem in item.column">
                                    <el-option :label="opitem.label" :value="opitem.value" :key="opitem.value"></el-option>
                                </template>
                            </el-select>
                        </template>
                        <template v-else-if="item.type == 'switch'">
                            <el-switch
                                v-model="form[item.prop]"
                                :active-text="item.activeText"
                                :inactive-text="item.inactiveText">
                            </el-switch>
                        </template>
                        <template v-else-if="item.type == 'checkbox-group'">
                            <el-checkbox-group v-model="form[item.prop]">
                                <template v-for="checkitem in item.column">
                                    <el-checkbox :label="checkitem.label" :name="item.prop" :key="checkitem.label"></el-checkbox>
                                </template>
                            </el-checkbox-group>
                        </template>
                        <template v-else-if="item.type == 'radio-group'">
                            <el-radio-group v-model="form[item.prop]">
                                <template v-for="radioitem in item.column">
                                    <el-radio :label="radioitem.label" :name="item.prop" :key="radioitem.label"></el-radio>
                                </template>
                            </el-radio-group>
                        </template>
                        <template v-else-if="item.type == 'time'">
                            <el-time-select
                                v-model="form[item.prop]"
                                placeholder="选择时间">
                            </el-time-select>
                        </template>
                        <template v-else-if="item.type == 'timerange'">
                            <el-time-picker
                                is-range
                                v-model="form[item.prop]"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                placeholder="选择时间范围">
                            </el-time-picker>
                        </template>
                        <template v-else-if="item.type == 'date'">
                            <el-date-picker
                                v-model="form[item.prop]"
                                type="date"
                                placeholder="选择日期">
                            </el-date-picker>
                        </template>
                        <template v-else-if="item.type == 'daterange'">
                            <el-date-picker
                                v-model="form[item.prop]"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </template>
                        <template v-else-if="item.type == 'datetime'">
                             <el-date-picker
                                v-model="form[item.prop]"
                                type="datetime"
                                placeholder="选择日期时间">
                            </el-date-picker>
                        </template>
                        <template v-else-if="item.type == 'datetimerange'">
                            <el-date-picker
                                v-model="form[item.prop]"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </template>
                        <template v-else-if="item.type == 'textarea'">
                            <el-input type="textarea" v-model="form[item.prop]"></el-input>
                        </template>
                        <template v-else>
                            <el-input v-model="form[item.prop]"></el-input>
                        </template>
                    </el-form-item>
                </template>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addvis = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <!--修改弹框-->
        <el-dialog title="修改" :visible.sync="editvis" :close="edithandleClose" :close-on-click-modal="false">
            <el-form :model="editForm" ref="ruleForm" :label-width="tableOptions.labelWidth?tableOptions.labelWidth:''" :rules="tableOptions.rules">
                <template v-for="item in tableOptions.columns">
                    <el-form-item :key="item.prop" :prop="item.prop" :label="item.label" v-if="!item.editHide">
                        <template v-if="item.type === 'select'">
                            <el-select v-model="editForm[item.prop]">
                                <template v-for="opitem in item.column">
                                    <el-option :label="opitem.label" :value="opitem.value" :key="opitem.value"></el-option>
                                </template>
                            </el-select>
                        </template>
                        <template v-else-if="item.type === 'switch'">
                            <el-switch
                                v-model="editForm[item.prop]"
                                :active-text="item.activeText"
                                :inactive-text="item.inactiveText">
                            </el-switch>
                        </template>
                        <template v-else-if="item.type === 'checkbox-group'">
                            <el-checkbox-group v-model="editForm[item.prop]">
                                <template v-for="checkitem in item.column">
                                    <el-checkbox :label="checkitem.label" :name="item.prop" :key="checkitem.label"></el-checkbox>
                                </template>
                            </el-checkbox-group>
                        </template>
                        <template v-else-if="item.type === 'radio-group'">
                            <el-radio-group v-model="editForm[item.prop]">
                                <template v-for="radioitem in item.column">
                                    <el-radio :label="radioitem.label" :name="item.prop" :key="radioitem.label"></el-radio>
                                </template>
                            </el-radio-group>
                        </template>
                        <template v-else-if="item.type === 'time'">
                            <el-time-select
                                v-model="editForm[item.prop]"
                                placeholder="选择时间">
                            </el-time-select>
                        </template>
                        <template v-else-if="item.type === 'timerange'">
                            <el-time-picker
                                is-range
                                v-model="editForm[item.prop]"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                placeholder="选择时间范围">
                            </el-time-picker>
                        </template>
                        <template v-else-if="item.type === 'date'">
                            <el-date-picker
                                v-model="editForm[item.prop]"
                                type="date"
                                placeholder="选择日期">
                            </el-date-picker>
                        </template>
                        <template v-else-if="item.type === 'daterange'">
                            <el-date-picker
                                v-model="editForm[item.prop]"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </template>
                        <template v-else-if="item.type === 'datetime'">
                             <el-date-picker
                                v-model="editForm[item.prop]"
                                type="datetime"
                                placeholder="选择日期时间">
                            </el-date-picker>
                        </template>
                        <template v-else-if="item.type === 'datetimerange'">
                            <el-date-picker
                                v-model="editForm[item.prop]"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </template>
                        <template v-else-if="item.type === 'textarea'">
                            <el-input type="textarea" v-model="editForm[item.prop]"></el-input>
                        </template>
                        <template v-else>
                            <el-input v-model="editForm[item.prop]"></el-input>
                        </template>
                    </el-form-item>
                </template>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editvis = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <el-drawer
            title="表格信息设置"
            :visible.sync="drawer">
            <div class="drawer-checkbox-group">
                <!-- <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                <div style="margin: 15px 0;"></div> -->
                <el-checkbox-group @change="setLabel" v-model="checkedCities">
                    <div class="check-item" v-for="item in tableOptions.columns" :key="item.prop">
                        <el-checkbox :label="item.label" :checked="!item.hide"  ></el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import { getDic,getData} from '@/api/mc.js';
export default {
    name:'basicTable',
    filters:{
        formatSelect(value,item){
            let str = value;
            item.column.forEach(it=>{
              //有自定义键值对应数组，采用自定义键值进行渲染列表
              if (item.propformat && item.propformat != '') {
                if (it[item.propformat.value] == value) {
                  str = it[item.propformat.label];
                }
              } else {
                if (it.value == value) {
                  str = it.label
                }
              }
            })
            return str
        }
    },
    props: {
        tableData:{
            type: Array,
            default: ()=>[]
        },
        tableOptions:{
            type:Object,
            default:()=>{}
        },
        pageData:{
            type:Object,
            default:()=>{}
        },
    },
    data(){
        return{
            loading:false,
            checkAll:false,
            checkedCities:[],
            isIndeterminate: true,
            addvis:false,
            editvis:false,
            rowIndex:null,
            form:{},//新增对象
            editForm:{},//编辑对象
            searchForm:{},//查询对象
            drawer:false,
            showFilter_coll:false,
        }
    },
    created(){
        var flag = false;
        var arr = this.tableOptions.searchcolumns,sFlag = false;
        for (var i = 0; i < arr.length; i++) {
            if(arr[i].defaultValue||arr[i].defaultValue!==''||arr[i].defaultValue===0){
                this.$set(this.searchForm,arr[i].prop,arr[i].defaultValue)
                // flag = true;
            }
        }
        if(this.tableOptions.defShowSearch){//搜索项默认展开
            // this.showFilter_coll = true
            this.$set(this,'showFilter_coll',true)
        }
        this.checkDic();
        // if(flag){
        //     this.onSearch();
        // }
    },
    methods:{
        checkDic(){
            var _this = this
            var arr1 = _this.tableOptions.columns;
            //将var i 改为let i
            for (let i = 0; i < arr1.length; i++) {
                var item = arr1[i];
                if(item.dicUrl&&item.dicUrl!=''){
                    var params = {
                        data:{
                            key:item.dicKey,
                        },
                        face:item.dicUrl
                    }
                    getDic(params).then(res=>{
                        if(res.success){
                            var temp = {
                                index:i,
                                list:res.data
                            }
                            this.$emit('changeColumnOption',temp);
                        }
                    })
                }
            }
            var arr = _this.tableOptions.searchcolumns;
            setTimeout(function(){
                if(arr.length>0){
                    for (var i = 0; i < arr.length; i++) {
                        var item = arr[i];
                        if(item.dicUrl&&item.dicUrl!=''){
                            if(item.getType&&item.getType == 'test'){
                                var temp = {
                                    data:item.getParams,
                                    face:item.dicUrl
                                }
                                _this.getSearchColum(temp,i,'test');
                            }else{
                                var temp = {
                                    data:{
                                        key:item.dicKey,
                                    },
                                    face:item.dicUrl
                                }
                                _this.getSearchColum(temp,i,'');
                            }
                        }
                    }
                }
            },1500)
        },
        getSearchColum(temp,i,type){
            var tmp = {
                index:i,
            }
            if(type == 'test'){
                getData(temp).then(res=>{
                    if(res.success){
                        tmp.list=res.data
                        this.$emit('changeSearchOption',tmp);
                    }
                })
            }else{
                getDic(temp).then(res=>{
                    if(res.success){
                        tmp.list=res.data
                        this.$emit('changeSearchOption',tmp);
                    }
                })
            }
        },
        handleCheckAllChange(val) {
            this.checkedCities = val ? this.checkedCities : [];
            this.isIndeterminate = false;
        },
        setLabel(){
            let arr = JSON.parse(JSON.stringify(this.tableOptions.columns));
            arr.forEach((item,index) => {
                if(this.checkedCities.indexOf(item.label)>-1){
                    delete item.hide;
                }else{
                    item.hide = true;
                }
            });
            this.$emit('changeColumn',arr);
        },
        onSubmit(){
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    if(this.addvis){
                        // let arr = JSON.parse(JSON.stringify(this.tableData));
                        // arr.push(this.form);
                        // this.$set(this,'tableData',arr);
                        // this.$emit('changeTable',arr);
                        this.$emit('addHandle',this.form)
                        // this.$nextTick(function(){
                        //     this.handleClose();
                        // })
                    }else if(this.editvis){
                        this.$emit('editHandle',this.editForm)
                        // let arr = JSON.parse(JSON.stringify(this.tableData));
                        // arr[this.rowIndex] = this.editForm;
                        // this.$emit('changeTable',arr);
                        // this.$nextTick(function(){
                        //     this.edithandleClose()
                        // })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        handleClose(){
            this.form = {};
            this.addvis = false;
        },
        edithandleClose(){
            this.editForm = {};
            this.rowIndex = null;
            this.editvis = false;
        },
        handleClick(row){
            console.log(row);
        },
        handleEdit(row,index){
            this.editForm = row;
            this.rowIndex = index;
            this.editvis = true;
        },
        handleDel(row,index){
            this.$confirm('是否确认要删除第'+(index+1)+'项?', '提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
            .then( async () => {
                this.$emit('delHandel',row,index);
                // let arr = JSON.parse(JSON.stringify(this.tableData));
                // arr.splice(index, 1);
                // this.$emit('changeTable',arr);
                // this.$message({
                //     type:'success',
                //     message:'删除成功'
                // })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })
        },
        handleSizeChange(val) {
            this.$emit('handleSizeChange',val);
        },
        handleCurrentChange(val) {
            this.$emit('handleCurrentChange',val);
        },
        pageRefree(){
            this.$emit('pageRefree');
        },
        onSearch(){
            var params = JSON.parse(JSON.stringify(this.searchForm));
            var arr = this.tableOptions.searchcolumns;
            for (var i = 0; i < arr.length; i++) {
                if(arr[i].searchcustom){
                    delete params[arr[i].prop]
                }
            }
            console.log(params)
            this.$emit('searchHandle',params);
        },
        clearSearch(){
            var m = JSON.parse(JSON.stringify(this.searchForm))
            for (var key in m) {
                if (Object.hasOwnProperty.call(m, key)) {
                    m[key] = ''
                }
            }
            this.searchForm = m;
            this.$emit('setSearchRefree');
        },
        handleCommand(){
            this.clearSearch()
        },
        shetFilternav(){
            var f = this.showFilter_coll
            this.$set(this,'showFilter_coll',!f)
        },
        berefree(){
            let that = this;
            that.$set(that,'loading',true);
            setTimeout(function(){
                that.$set(that,'loading',false);
            },1000)
        },
        setFormat(row, column, cellValue, index){
            // this.$moment(row[column.property]).format("YYYY-MM-DD")
            return this.$moment(row[column.property]).format(column.formatValue)
        },
        handleSelectionChange(val){
            this.$emit('selectionChange',val);
        },
        handleSortChange(val){
            this.$emit('sortChange',val);
        },
        clearSelection(){
            this.$refs.basicTable.clearSelection();
        },
        toggleRowSelection(rows){
            rows.forEach(row => {
                this.$refs.basicTable.toggleRowSelection(row);
            });
        },
        tableRowClassName({row, rowIndex}){
            var s = this.tableOptions.RowClassrender(row)
            if(s){
                return s
            }
            return '';
        },
        //秒格式化为对应分钟
        formatTime(value) {
          var secondTime = parseInt(value); // 秒
          var minuteTime = 0; // 分
          var hourTime = 0; // 小时
          if (secondTime > 60) { //如果秒数大于60，将秒数转换成整数
            //获取分钟，除以60取整数，得到整数分钟
            minuteTime = parseInt(secondTime / 60);
            //获取秒数，秒数取余，得到整数秒数
            secondTime = parseInt(secondTime % 60);
            //如果分钟大于60，将分钟转换成小时
            if (minuteTime > 60) {
              //获取小时，获取分钟除以60，得到整数小时
              hourTime = parseInt(minuteTime / 60);
              //获取小时后取余的分，获取分钟除以60取余的分
              minuteTime = parseInt(minuteTime % 60);
            }
          }
          var result = "" + parseInt(secondTime) + "秒";
          if (minuteTime > 0) {
            result = "" + parseInt(minuteTime) + "分" + result;
          }
          if (hourTime > 0) {
            result = "" + parseInt(hourTime) + "时" + result;
          }
          return result;
        }
    },
    updated(){
        var _this = this;
        setTimeout(function(){
            _this.$nextTick(()=>{
                _this.$refs.basicTable.doLayout();
            })
        },1000)
    }
}
</script>
<style lang="less" scoped>
.table{
    // padding: 10px 20px;
    width: 100%;
    height: calc(100% - 60px);
    &.border{
        border: 1px solid #d1d5de;
    }
    &.large{
        height: 100%;
        .table_body{
            height: 100%;
        }
    }
    &.allTable{
        height: 100%;
    }
    .table_search{
        text-align: left;
        padding: .22rem .24rem 0;

    }
    .panel{
        margin-bottom: .14rem;
        background-color: #ffffff;
    }
    .table-main{
        width: 100%;
        height: 100%;

    }
    .table-main.border{
        border: 1px solid #e6ebf2;
    }
    .table_body{
        background-color: #ffffff;
        position: relative;
        width: 100%;
        height: calc(100% - 60px);
        /deep/.el-table {
            .cell{
                display: -webkit-box;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                .el-dropdown{
                    margin-left: 10px;
                }
            }
        }
        .table_sys_right{
            position: absolute;
            top: 0;
            right: 0;
            z-index: 999;
            width: 50px;
            height: 50px;
        }
    }
    .table_head{
        background-color: #fff;
        .sys_head_tab{
            span{
                display: inline-flex;
                margin: 0 .05rem;
            }
        }
    }
    .pageFooter{
        width: 100%;
        padding: .1rem;
        background-color: #ffffff;
        position: relative;
    }
}
.drawer-checkbox-group{
    padding-left: 20px;
    .check-item{
        padding: 10px 0;
    }
}
.filter_are{
    /deep/.el-form{
        .el-form-item{
            margin-bottom: 0;
            .el-form-item__label{
                min-width: 80px;
            }
        }
    }
}
</style>
