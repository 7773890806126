/**axios封装
请求拦截、相应拦截、错误统一处理

*/

import axios from 'axios';
import QS from 'qs';
import store from '@/store'
import router from '../router'
import md5 from 'js-md5';
import { Message, MessageBox,Loading } from 'element-ui'
import inters from './url'

// 环境的切换
if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = '/api';
} else if (process.env.NODE_ENV == 'debug') {
    axios.defaults.baseURL = '';
} else if (process.env.NODE_ENV == 'production') {
    // axios.defaults.baseURL ='https://edsp.sptesyun.com'//正式环境
    axios.defaults.baseURL ='https://paperless.tctob.com/prod-api'
    //axios.defaults.baseURL ='http://10.100.1.68:8888';//测试环境
    // axios.defaults.baseURL ='/edsp-webapp/';//新域名
}

// 请求超时时间
axios.defaults.timeout = 10000;


// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


// 请求拦截器

axios.interceptors.request.use(config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        let user = window.localStorage.getItem('user');
        const token = window.localStorage.getItem('token');//store.state.token;
        config.headers.source='web'
        config.headers['version'] = '2.0.0';
        if(config.params.responseType&&config.params.responseType == 'arraybuffer'){
            delete config.params.responseType
            config.responseType= 'arraybuffer';
        }
        if(config.face=='setPdfNew'){
            config.timeout = 600000
        }else if(config.face == 'setPdf'){
            config.timeout = 200000
        }else if(config.face == 'upFile'||config.face == 'addAuthUserFile'){
            config.timeout = 200000
        }
        
        if(user&&user!=''){
            user = JSON.parse(user);
            let s = randomString(10);
            let t = new Date().getTime();
            let set = '';
            config.headers.userName = user.userName;
            config.headers.salt = s;
            config.headers.ts = t;
            config.headers.seal = user.seal
            // config.headers.token = token;
            if(config.method == 'get'){
                set = 'userName='+user.userName+'&salt='+s+'&seal='+user.seal+'&ts='+t+'&token='+token;
                if(config.params&&config.params!=''){
                    for(var key in config.params){
                        if(config.params[key]===''||config.params[key]===undefined||config.params[key]===null){
                            delete config.params[key]
                        }else{
                            set+='&'+key+'='+(config.params[key]+'').Trim();
                        }
                    }
                }
            }else{
                set = 'userName='+user.userName+'&salt='+s+'&seal='+user.seal+'&ts='+t+'&token='+token;
                if(config.params&&config.params!=''){
                    for(var key in config.params){
                        if(config.params[key]===''||config.params[key]===undefined||config.params[key]===null){
                            delete config.params[key]
                        }
                    }
                }
                // for(var key in config.params){
                //     set+='&'+key+'='+config.params[key];
                // }
            }
            let si = md5(set);
            config.headers.sign = si;
            if(config.params.ajaxType&&config.params.ajaxType == 'file'){
                var param = new FormData(); 
                param.append("file", config.params.file);
                config.data = param;
                delete config.params
            }else{
                if(config.params&&config.params!=''){
                    for(var key in config.params){
                        if(config.params[key]===''||config.params[key]===undefined||config.params[key]===null){
                            delete config.params[key]
                        }else{
                            config.params[key] =  (config.params[key]+'').Trim();
                        }
                    }
                }
                if(config.method == 'post'){
                    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8';
                    config.data = QS.stringify(config.params) || {}
                    delete config.params;
                }else{
                    config.params = config.data || config.params || {}
                }
            }
        }else{
            token && (config.headers.Authorization = token);
        }
        config.url = inters[config.face];
        console.log(config)
        return config;
    },error => {
        return Promise.error(error);
    }
)
function randomString(len) {
　　len = len || 32;
　　var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
　　var maxPos = $chars.length;
　　var pwd = '';
　　for (var i = 0; i < len; i++) {
　　　　pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
　　}
　　return pwd;
}
String.prototype.Trim = function()  
{  
return this.replace(/(^\s*)|(\s*$)/g, "");  
}  
// 响应拦截器
axios.interceptors.response.use(response => {
    console.log(response)
    if (response.status === 200) {
        if(response.data.code == 200){
            return Promise.resolve(response.data);
        }else{
            if((response.data.code == -100&&response.data.message == 'token失效')||(response.data.code == -100&&response.data.message == '登录状态已过期')||(response.data.code == -100&&response.data.message == "用户已在其他地方登录")||(response.data.code == -1&&response.data.message == '请先登录')){                
                window.sessionStorage.clear();
                window.localStorage.clear();
                router.replace({
                    path: '/login',
                    query: { redirect: router.currentRoute.fullPath }
                });
            }else{
                if(response.data.code == -1){
                    Message.error({ message: response.data.message })
                    return Promise.resolve(response.data);
                }else{
                    return Promise.resolve(response.data);
                    // Message.error({ message: response.data.message }) 
                    // return Promise.reject(response);
                }
            }
        }
    } else {
        if((response.data.code == -100&&response.data.message == 'token失效')||(response.data.code == -100&&response.data.message == '登录状态已过期')){
            window.sessionStorage.clear();
            window.localStorage.clear();
            router.replace({
                path: '/login',
                query: { redirect: router.currentRoute.fullPath }
            });
        }else{
            if(response.data.code == 400){
                Message.error({ message: response.data.message })
                return Promise.resolve(response.data);
            }else{
                Message.error({ message: response.data.message }) 
                return Promise.reject(response);
            }
        }
    }
},error => {
    console.log(JSON.stringify(error));
// 服务器状态码不是200的情况
    if(error == 'Error: timeout of 10000ms exceeded'){
        Message.error({ message: '网络请求超时' }) 
        return false
    }
    if (error.response&&error.response.status) {
        switch (error.response.status) {
            // 401: 未登录
            // 未登录则跳转登录页面，并携带当前页面的路径
            // 在登录成功后返回当前页面，这一步需要在登录页操作。
            case 401:
                window.sessionStorage.clear();
                window.localStorage.clear();
                router.replace({
                    path: '/login',
                    query: { redirect: router.currentRoute.fullPath }
                });
            break;
            // 403 token过期
            // 登录过期对用户进行提示
            // 清除本地token和清空vuex中token对象
            // 跳转登录页面
            case 403:
                Message.error({ message: '登录过期，请重新登录' }) 
                // 清除token
                localStorage.removeItem('token');
                store.commit('loginSuccess', null);
                // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                setTimeout(() => {
                    window.sessionStorage.clear();
                    window.localStorage.clear();
                    router.replace({
                        path: '/login',
                        query: {
                        redirect: router.currentRoute.fullPath
                        }
                    });
                }, 1000);
            break;
            // 404请求不存在
            case 404:
                Message.error({ message: '网络请求不存在' }) 
            break;
            // 其他错误，直接抛出错误提示

            default:
                Message.error({ message: error.response.data.message }) 
            break;
        }
        return Promise.reject(error.response);
    }else{
        return Promise.reject(error.response);
    }
});

export default axios