import request from './axios.js'
import qs from 'qs';

//得到最新一条对话信息列表
export function getLastMessage(params,face) {
    return request({
        face:face,
        method: 'post',
        data:qs.stringify(params)
    });
}
export function getLastMessageGet(params,face) {
    return request({
        face:face,
        method: 'get',
        params
    });
}
export function getNameKey(params,face) {
    return request({
        face:face,
        method: 'get',
        params
    });
}
export function getUserInfo(params,face) {
    return request({
        face:face,
        method: 'post',
        params
    });
}
export function coding(params,face){
    return request({
        face:face,
        method: 'get',
        params
    });
}
export function phoneCode(params,face){
    return request({
        face:face,
        method: 'post',
        params
    });
}
export function updatePwd(params,face){
    return request({
        face:face,
        method:"post",
        params
    })
}
export function outLogin(params,face){
    return request({
        face:face,
        method:'delete',
        params
    })
}
export function getAuth(params,face){
    return request({
        face:face,
        method:'get',
        params
    })
}
export function getMenuList(params,face){
    return request({
        face:face,
        method:'get',
        params
    })
}
export function menuSys(params,face){
    return request({
        face:face,
        method:'post',
        params
    })
}
export function getMenuButton(params,face){
    return request({
        face:face,
        method:'get',
        params
    })
}
export function menuButtonSys(params,face){
    return request({
        face:face,
        method:'post',
        params
    })
}
export function getRoles(params,face){
    return request({
        face:face,
        method:'get',
        params
    })
}
export function setRoleItem(params,face){
    return request({
        face:face,
        method:'post',
        params
    })
}
export function getSysTree(params,face){
    return request({
        face:face,
        method:'get',
        params
    })
}
export function getRolePower(params,face){
    return request({
        face:face,
        method:'get',
        params
    })
}
export function setRolePower(params,face){
    return request({
        face:face,
        method:'post',
        params
    })
}
export function setMenuTrees(params,face){
    return request({
        face:face,
        method:'post',
        params
    })
}