<template>
    <div class="webpage">
        <router-view/>
    </div>
</template>
<script>
export default {
    name:'webLayout'
}
</script>
<style lang="less" scoped>
.webpage{
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;
    overflow: visible;
}
</style>