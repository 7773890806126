import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './global.less'
import Table from '@/components/basicTable.vue'
// import Card from '@/components/basicCard.vue'
import moment from 'moment'
import JSEncrypt from 'jsencrypt';
import md5 from 'js-md5';
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, { 
  ak: 'smGKihmYZaEBIPyib3H4lzkcpoSnKAWI'
})

Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.component("Table", Table);
// Vue.component("Card", Card);
Vue.prototype.$md5 = md5;
Vue.prototype.$moment = moment;
Vue.prototype.$getRsaCode = function(str,key){ 
  // 注册方法
  console.log(str);
  let pubKey = key;
 // ES6 模板字符串 引用 rsa 公钥
  let encryptStr = new JSEncrypt();
  encryptStr.setPublicKey(pubKey); // 设置 加密公钥
  let  data = encryptStr.encrypt(str);  // 进行加密
  console.log(data);
  return data;
}
new Vue({
  el: '#app',
  store,
  router,  
  render: h => h(App)
}).$mount('#app')