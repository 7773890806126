const urlList={
    'getNameKey':'/paperless-auth/auth/getKey',//获取公私钥对
    'getUserInfo':'/paperless-auth/auth/login',//登录获取用户信息
    'outlogin':'/paperless-auth/auth/logout',//退出登录
    'getcode':'/paperless-auth/auth/code',//获取图形验证码
    'sendPhoneCode':'/paperless-basedata/sms/sendSms',//获取短信验证码
    'sendPhoneCode1':'/paperless-user/auth/sendAddAuthCode',//获取短信验证码(新增员工)
    'setPwdnow':'/paperless-user/auth/updatePWD',//重置密码
    'forgetPwd':'/paperless-user/auth/forgetPWD',//忘记密码
    //平台菜单管理
    'authPage':'/paperless-user/auth/getAuthPage',//用户分页列表
    'getMenuList':'/paperless-user/menu/getMenuTree',//获取某级菜单列表
    'addMenuItem':'/paperless-user/menu/addMenu',//添加菜单项
    'editMenuItem':'/paperless-user/menu/editMenu',//修改菜单项
    'menuTreeEdit':'/paperless-user/menu/changeSeqAndPid',//修改平台菜单树
    'delMenuItem':'/paperless-user/menu/deleteMenu',//删除菜单项
    'getMenuButton':'/paperless-user/menuButton/getMenuButtonByMenuId',//获取按钮
    'addMenuButton':'/paperless-user/menuButton/addMenuButton',//增加按钮
    'editMenuButton':'/paperless-user/menuButton/editMenuButton',//修改按钮
    'delMenuButton':'/paperless-user/menuButton/deleteMenuButton',//删除按钮
    //平台角色管理
    'getRoleList':'/paperless-user/role/getPfRolePage',//获取角色列表
    'addRoleItem':'/paperless-user/role/addRole',//新增角色
    'editRoleItem':'/paperless-user/role/editRole',//修改角色
    'enableRoleItem':'/paperless-user/role/enableOrDisable',//启用/禁用角色
    'delRoleItem':'/paperless-user/role/removeRole',//启用/禁用角色
    //平台角色权限管理
    'getSysTree':'/paperless-user/menu/getMenuTree',//获取平台菜单树
    'getRolePower':'/paperless-user/menu/listByRoleCrop',//获取角色权限列表
    'setRolePower':'/paperless-user/relRoleMenu/settingRoleAuth',//设置平台角色权限
    // 'getCorpRoles':'/paperless-user/role/getCorpRolePage',//获取公司员工角色列表
    'editRoleMenuItem':'/paperless-user/relRoleMenu/editRelRoleMenu',//修改系统平台角色
    'getRoleTypes':'/paperless-user/role/getRoleType',//获取角色类型
    'getRoleCates':'/paperless-user/roleData/getChoiceRoleData',//获取角色权限选择项
    'getRoleProvs':'/paperless-basedata/dicZone/getMyDataCity',//获取角色省市权限列表
    'getRoleCitys':'/paperless-basedata/dicZone/getMyDataArea',//获取角色城市权限列表
    'getRoleValliges':'/paperless-basedata/park/getMyDataPark',//获取角色权限小区列表
    'getRoleLifts':'/paperless-basedata/devLift/getMyDataLift',//获取角色权限小区列表
    'setRoleDatas':'/paperless-user/roleData/setRoleData',//设置角色数据权限
    //平台用户管理
    'getAuthPage':'/paperless-user/auth/listAuthPage',//系统用户管理
    'addCorpRole':'/paperless-user/auth/addSysAuth',//新增公司用户
    'editCorpRole':'/paperless-user/auth/editAuth',//修改公司用户
    'editSysCorpRole':'/paperless-user/auth/editSysAuth',//修改系统用户
    'setCorpRolepwd':'/paperless-user/auth/resetPWD',//重置公司用户密码
    'disCorpRole':'/paperless-user/auth/disableAuth',//禁用公司用户
    'enaCorpRole':'/paperless-user/auth/enableAuth',//启用公司用户
    'delCorpRole':'/paperless-user/auth/delAuth',//删除公司用户
    'getAuthInfo':'/paperless-user/auth/getAuthInfo',//获取用户详情
    'setDataPower':'/paperless-user/authData/setAuthData',//设置员工数据权限
    'getDataPower':'/paperless-user/authData/getAuthDataInfo',//获取员工数据权限
    'getDataPower1':'/paperless-user/authData/getAuthDataInfoByView',//获取用户电梯数据权限详细信息(用于设置反显)
    'getUserMcYearView':'/paperless-mc/devMc/auth-year',//员工维保年视图
    'getUserMcMounthView':'/paperless-mc/devMc/auth-month',//员工维保月视图
    'getNumByids':'/paperless-basedata/devLift/getMcLiftByCorp',//根据选择条件获取维保公司维保电梯数量
    'getNumByidsList':'/paperless-basedata/devLift/getMcLiftByCorpList',//根据选择条件获取维保公司维保电梯列表
    //运维管理--公司管理
    'getCompany':'/paperless-user/corp/getCorpPage',//公司列表
    'getAddrList':'/paperless-basedata/dicZone/listByPid',//获取地区列表
    'getAddrTree':'/paperless-basedata/dicZone/getMyTree',//获取地区树（带权限）
    'getCorpRoleList':'/paperless-user/role/getCorpRolePage',//获取普通(公司)角色列表
    'editCorp':'/paperless-user/corp/editCorp',//修改公司
    'disCorpItem':'/paperless-user/corp/disableCorp',//禁用公司
    'enablCorpItem':'/paperless-user/corp/enableCorp',//启用公司
    'delCorpItem':'/paperless-user/corp/delCorp',//删除公司
    'getCorpRoles':'/paperless-user/role/getRoleByCorp',//获取公司角色列表
    'getCorpInfo':'/paperless-user/corp/getCorpInfo',//获取单位详情
    'changeCorp':'/paperless-user/auth/transferUnit',//转移公司（单位）
    'addCorp':'/paperless-user/corp/addCorp',//新增单位
    'getCorpRuleList':'/paperless-user/role/selectRoleList',//获取平台公司角色
    //运维管理--用户管理
    'getDevRules':'/paperless-user/auth/getAuthPage',//运维用户管理
    'addDevRule':'/paperless-user/auth/addAuth',//新增运维用户
    'getChancelList':'/paperless-mc/mcChannel/getMcChannelPage',//维保API渠道单位分页列表
    'addChancelItem':'/paperless-mc/mcChannel/addMcChannel',//新增维保API渠道单位
    'editChancelItem':'/paperless-mc/mcChannel/editMcChannel',//修改维保API渠道单位
    'delChancelItem':'/paperless-mc/mcChannel/removeMcChannel',//删除维保API渠道单位
    'getSysRoleType':'/paperless-user/role/getRoleType',//获取平台角色类型
    'getRoleTree':'/paperless-user/role/getRoleTypeTree',//获取角色类型树(运维获取用户角色)
    'getChancelPeoples':'/paperless-user/authChannel/getAuthChannelByAppid',//获取渠道用户列表
    'addChancelPeople':'/paperless-user/authChannel/addAuthChannel',//新增渠道用户
    'delChancelPeople':'/paperless-user/authChannel/removeAuthChannel',//删除渠道用户
    'clearPeopleMcStorage':'/paperless-user/auth/clearMcCache',//清除用户维保缓存
    //维保模板管理
    'liftType':'/paperless-basedata/dicParam/getDicByKey',//电梯类型
    'modeList':'/paperless-mc/devMcTmpl/getTmplList',//电梯类型
    'liftModule':'/paperless-mc/devMcTmpl/getItemList',//配置详情
    'addMcTmpl':'/paperless-mc/devMcTmpl/addDevMcTmpl',//新增自定义项
    'editMcTmpl':'/paperless-mc/devMcTmpl/editDevMcTmpl',//修改自定义项
    'delMcTmpl':'/paperless-mc/devMcTmpl/removeDevMcTmpl',//删除自定义项
    'needModeList':'/paperless-mc/needTmplS/getNeedTmplSPage',//按需维保模板设置分页列表
    'getNeedModeInfo':'/paperless-mc/needTmplS/getTmplSByItem',//根据按需维保模板编号获取维保项
    'getSysNeedModeInfo':'/paperless-mc/needTmplS/getTmplSBySysItem',//获取系统模板维保项
    'addNeedTmpls':'/paperless-mc/needTmplS/addNeedTmplS',//新增按需维保模板设置
    'editNeedTmpls':'/paperless-mc/needTmplS/editNeedTmplS',//修改按需维保模板设置
    'delNeedTmpls':'/paperless-mc/needTmplS/removeNeedTmplS',//删除按需维保模板设置
    'getNeedModeList':'/paperless-mc/needTmplS/getTmplSByItem',//根据按需维保模板编号获取维保项
    'getNeedApplyList':'/paperless-mc/needApply/getNeedApplyPage',//按需维保申请单分页列表
    'getNeedApplyItem':'/paperless-mc/needApply/getNeedApplyInfo',//获取按需维保申请单详细信息
    'setNeedParks':'/paperless-mc/needTmplS/setupNeedTmplSPark',//设置按需维保模板适用小区
    'getNeedParks':'/paperless-mc/needTmplS/getNeedTmplSInfo',//获取按需维保模板设置详细信息
    //定期维保管理
    'areaByPid':'/paperless-basedata/dicZone/listByPid',//获取区县列表
    'mcRegularList':'/paperless-mc/devMc/timeList',//定期维保列表
    'timeMcSchedule':'/paperless-mc/devMc/mcSchedule',//维保排期
    'updateTimeMcSchedule':'/paperless-mc/devMc/batchUpdMcDtm',//修改维保排期
    'mcPlanView':'/paperless-mc/devMc/planListDay',//维保排期日历
    'mcReport':'/paperless-mc/devMc/getDevMcPage',//电梯维保记录
    'devMcPlan':'/paperless-mc/devMc/preMcSchedule',//获取排期安排
    'getMcScheduleAsc':'/paperless-mc/devMc/preMcScheduleAsc',//正推定期维保预排期
    'setMcScheduleAsc':'/paperless-mc/devMc/mScheduleAsc',//正推定期维保排期
    //按需维保列表
    'getMcNeedList':'/paperless-mc/needMc/list',//按需维保列表
    'mcNeedInfo':'/paperless-mc/needMc/info',//按需维保详情
    'needCustomInfo':'/paperless-mc/needTmpl/getNeedCustomItem',//获取派单自定义详情
    'mcNeedMenuPreview':'/paperless-mc/needTmpl/getMustItem',//按需维保预览项
    'mcNeedNow':'/paperless-mc/needMc/check',//按需维保提醒信息
    'solveMcNeedNow':'/paperless-mc/needMc/checkSolve',//处理按需维保电梯提醒信息
    'corpTmpl':'/paperless-mc/needTmpl/getCorpTmpl',//根据电梯获取公司模板选项列表
    'addCorpTmpl':'/paperless-mc/needTmpl/addNeedTmpl',//新增自定义按需维保项
    'editCorpTmpl':'/paperless-mc/needTmpl/editNeedTmpl',//修改自定义按需维保项
    'delCorpTmpl':'/paperless-mc/needTmpl/removeNeedTmpl',//删除自定义按需维保项
    'addCustomCorp':'/paperless-mc/needCustom/addNeedCustomItem',//增加自定义项（派单时自定义项）
    'getPrivewInfo':'/paperless-mc/needMc/previewItem',//根据电梯获取预览项
    'mcNeedDispatch':'/paperless-mc/needMc/dispatch',//按需维保派单
    'cancelNeedLifts':'/paperless-mc/needApply/cancelNeed',//取消电梯安需维保
    //按需维保配置
    'modeNeedList':'/paperless-mc/needTmpl/getMyMustItemListPage',//获取按需维保配置列表
    'menuTmplNeedList':'/paperless-mc/needTmpl/getOptionalItem',//获取按需维保配置项
    'addNeedMenu':'/paperless-mc/needTmpl/addNeedTmpl',//新增按需维保项
    'editNeedMenu':'/paperless-mc/needTmpl/editNeedTmpl',//修改按需维保项
    'delNeedMenu':'/paperless-mc/needTmpl/removeNeedTmpl',//删除按需维保项
    'setNeedMenu':'/paperless-mc/needCom/addMustItem',//设置按需维保必填项
    'delneedItem':'/paperless-mc/needCom/deleteMustItem',//删除必填项
    //维保审核列表
    'mcExamList':'/paperless-mc/appMc/auditWaitList',//获取维保审核列表
    'mcExamImte':'/paperless-mc/appMc/getMcByMcId',//获取维保审核详情
    'mcExamSave':'/paperless-mc/appMc/auditMc',//提交维保审核
    'getMcOverSate':'/paperless-mc/devMc/getOverMcLiftTotal',//维保超期审核电梯台数统计
    'exportMcOverList':'/paperless-mc/export/excelOverMc',//导出维保超期审核excel
    //我的电梯
    'liftList':'/paperless-basedata/devLift/listDevLiftPage',//电梯列表
    'getAreTree':'/paperless-basedata/dicZone/getProvTree',//获取省市区树
    'getMcViewYear':'/paperless-mc/devMc/view-year',//维保列表年视图
    'getMcViewMonth':'/paperless-mc/devMc/view-month',//维保列表月视图
    'openMcNeed':'/paperless-mc/devMcApply/openNeedMc',//开通按需维保
    'liftInfo':'/paperless-basedata/devLift/getLift',//获取电梯详情
    'getLiftTree':'/paperless-basedata/devLift/getMyDataLift',//根据小区获取电梯列表
    'editLiftName':'/paperless-mc/devLiftAnotherName/updateDevLiftAnotherName',//设置电梯备注
    'mcNeedApply':'/paperless-mc/needApply/addNeedApply',//新增按需维保申请单
    'getMcDaycount':'/paperless-mc/allKind/getTodayMcLiftCount',//得到今天的工作量
    'getMcNeedPlans':'/paperless-mc/needApply/getMyMcWorkload',//获取15内我的维保工作量
    'getNeedMcList':'/paperless-mc/devMc/needList',//按需维保列表
    'getAddLiftList':'/paperless-basedata/devLift/getNeedApplyLiftPage',//获取可选开通按需维保电梯列表
    'delNeedPlanLift':'/paperless-mc/devMc/delMc',//删除电梯列表的当前日期后的未做排期
    //我的小区
    'getVallige':'/paperless-basedata/park/getParkPage',//小区列表
    'valligeInfo':'/paperless-basedata/park/getParkInfo',//小区详情
    'setValligeInfo':'/paperless-basedata/park/editPark',//修改小区
    'getValligeTree':'/paperless-basedata/park/getMyDataPark',//获取小区树
    'getParkMcList':'/paperless-mc/export/excelMcPlan',//获取小区维保记录
    //其他
    'getParkTree':'/paperless-basedata/park/getMyParkTree',//模糊搜索返回树
    'getLiftTree1':'/paperless-basedata/devLift/getMyLiftTree',//模糊搜索返回树(电梯编码)
    'getCitList':'/paperless-basedata/dicZone/getMyDataCity',//请求城市列表
    'getAreList':'/paperless-basedata/dicZone/getMyDataArea',//请求区县列表
    'getTransLift':'/paperless-mc/devLift/listPCTransferLiftPage',//获取转单电梯
    'getTransLiftInfo':'/paperless-mc/devLift/getPCTransferLift',//获取转单电梯详情
    'getTransRuleList':'/paperless-user/auth/listAuthByCorp',//获取转单选项人员列表
    'saveTrans':'/paperless-mc/devMcTransfer/saveBatchRecord',//提交转单
    'cancelTrans':'/paperless-mc/devMcTransfer/cancelBatchTransfer',//取消转单
    'getTransList':'/paperless-mc/devMcTransfer/listDevMcTransferPage',//获取转单记录列表
    'getRoadTree':'/paperless-basedata/park/getMyDataStreet',//获取质监局所在的街道列表
    'getRoleRoadInfo':'/paperless-user/roleData/getRoleDataInfo',//获取角色数据权限详细信息（街道）
    //工作台
    'getAllCount':'/paperless-mc/allKind/getPcAllCount',//各种数量统计
    'getYearCount':'/paperless-mc/allKind/getPcInsuranceAndYearCount',//保险和年检数量统计
    'getDevCateCount':'/paperless-mc/allKind/getPcLiftDevCateCount',//电梯场所数量统计
    'getMcTypeCount':'/paperless-mc/allKind/getPcLiftMcTypeCount',//维保类型数量统计
    'getMcLine':'/paperless-mc/allKind/getPcMcCalendarCount',//（折线图）已维保/超期
    'upFile':'/paperless-mc/needApply/uploadNeedApply',//上传文件
    //平台维护信息
    'getdefList':'/paperless-mc/mcInfo/getMcInfoPage',//平台维护信息分页列表
    'getdefInfo':'/paperless-mc/mcInfo/getMcInfoInfo',//获取平台维护信息详细信息
    //切换用户角色
    'changeRole':'/paperless-auth/auth/switchRole',//
    //公司设置
    'getCompanyDic':'/paperless-user/corpPrivilege/getCorpPrivilegePage',//获取单位设置字典列表
    'getCompanyDicInfo':'/paperless-user/corpPrivilege/getPrivilegeByKey',//获取单位设置字典详情
    'editCompanyDicInfo':'/paperless-user/corpPrivilege/setCorpPrivilege',//新增、修改设置字典详情
    //生成pdf
    'setPdf':'/paperless-basedata/file/download/pdf',//生成pdf
    'setPdfNew':'/paperless-basedata/file/download/zip',//生成pdfzip
    //超期审核
    'getOverMcList':'/paperless-mc/devMc/getOverMcPage',//获取维保超期审核列表
    'addOverMcItem':'/paperless-mc/mcOverAudt/addMcOverAudt',//审核新增
    'delOverMcItem':"/paperless-mc/mcOverAudt/removeMcOverAudt",//撤销审核
    'getOverMcItem':'/paperless-mc/mcOverAudt/getMcOverAudtInfo',//审核详情
    'addOverMcItems':'/paperless-mc/mcOverAudt/addBatchMcOverAudt',//批量审核新增
    //任务管理
    'getTaskLists':'/paperless-job/sysJob/getSysJobPage',//定时任务调度表分页列表
    'addTaskItem':'/paperless-job/sysJob/addSysJob',//新增定时任务调度表
    'editTaskItem':'/paperless-job/sysJob/editSysJob',//修改定时任务调度表
    'delTaskItem':'/paperless-job/sysJob/removeSysJob',//删除定时任务调度表
    'getTaskReports':'/paperless-job/sysJobLog/getSysJobLogPage',//定时任务调度日志表分页列表
    //运维管理-日志管理
    'getLoginReports':'/paperless-user/logAuthLogin/getLogAuthLoginPage',//查询登录日志
    'getOperateReports':'/paperless-user/sysOperLog/getSysOperLogPage',//查询操作日志记录
    //96333搬迁功能
    'getAttestations':'/paperless-cq96333/cq96333Uuid/getUuidPage',//获取小区认证码列表
    'getAttestBindList':'/paperless-cq96333/cq96333Uuid/getCodeStat',//获取认证情况列表
    'getAttestLiftList':'/paperless-cq96333/devLift/getLiftConfirmPage',//获取电梯认领分页列表
    'getAttestAreaList':'/paperless-cq96333/cq96333Uuid/getUuidByAreaPage',//获取区县认证码分页列表
    'getAttestYadeList':'/paperless-cq96333/baseInfo/getBaseInfoPage',//亚德数据列表
    'getLiftListZip':'/paperless-cq96333/devLift/getLiftConfirmZip',//下载电梯认领情况表zip(批量)
    'getAreaAttestList':'/paperless-cq96333/cq96333Uuid/getUuidByAreaZip',//批量下载区县认证码zip
    'getWaringTestList':'/paperless-cq96333/alarm/getRealTimeAlarm',//获取实时告警列表
    'getWaringHistoryList':'/paperless-cq96333/alarm/getHistoryPage',//获取历史告警列表
    'getWaringTongji':'/paperless-cq96333/alarm/getStat',//获取告警统计
    'getTicketPage':'/paperless-cq96333/ticket/getTicketPage',//获取工单查询列表
    'getPhoneAlmPage':'/paperless-cq96333/phoneAlm/getPhoneAlmPage',//获取电话呼叫列表
    'getAllCsAuth':'/paperless-cq96333/phoneAlm/getAllCsAuth',//获取客服列表
    'getVideoPage':'/paperless-cq96333/video/getVideoPage',//获取视频呼叫列表
    'getVideoFile':'/paperless-cq96333/video/getVideoFile',//获取视频文件
    'getOrderCensus':'/paperless-cq96333/ticket/getStat',//获取工单统计信息
    'getParkLiftVideoList':'/paperless-cq96333/devLift/monitor-list',//监视的电梯列表（小区）
    'getMonitor':'/paperless-basedata/devIccm/rmi/monitor',//获取终端监视
    'getMonitorUrl':'/paperless-cq96333/videoMem/monitor-url',//是否通话监控
    'getLiftdataCensus':'/paperless-basedata/devLift/subtotal',//获取电梯数据统计
    'getLiftScreen':'/paperless-basedata/devLift/listLiftScreen',//获取电梯各种条件筛选报表
    'getLiftExport':'/paperless-basedata/devLift/expotScreen',//导出报表
    //报表相关
    'getMcReports':'/paperless-mc/devLift/listPcMcLiftCountPerByNew',//查看开展无纸化维保电梯占比（维保统计）
    'getMcReportsExport':'/paperless-mc/devLift/exportPcMcLiftCountPerByNew',//导出无纸化维保电梯占比
    'getIccmCensus':'/paperless-basedata/devIccm/getStat',//终端一体机统计
    'getdevIccmList':'/paperless-basedata/devIccm/getDevIccmPage',//终端一体机分页列表
    'getMcPdfList':'/paperless-mc/devLift/getMcTotalInfo',//查看维保统计详情列表
    //停梯相关
    'getStopMcLifts':'/paperless-basedata/devLiftStopMc/listDevLiftStopMcPage',//获取停梯维保生效表分页列表
    'getStopMcLiftsSate':'/paperless-basedata/devLiftStopMc/getStopStatistics',//获取停梯合计
    'setStopMcLift':'/paperless-basedata/devLiftStopMc/saveStopMc',//修改停梯维保排期状态
    //维保延期相关
    'getDelayList':'/paperless-mc/mcDelay/getMcDelayPage',//延期维保分页列表
    'addDelayItem':'/paperless-mc/mcDelay/addMcDelay',//新增延期维保
    'getDelayItem':'/paperless-mc/mcDelay/getMcDelayInfo',//获取延期维保详细信息
    'examDelayItem':'/paperless-mc/mcDelay/verifyMcDelay',//审核延期维保
    'recordDelayItem':'/paperless-mc/mcDelay/recordMcDelay',//延期维保备案
    'getDelayYadeList':'/paperless-mc/mcDelay/getYdMcDelayPage',//获取亚德延期电梯列表
    'setDelayYadeBack':'/paperless-mc/devMc/restoreSchedule',//批量恢复电梯排期
    //授权书相关
    'upBookfiles':'/paperless-user/corpEmpower/uploadCorpEmpower',//上传授权书
    'addBookFiles':'/paperless-user/corpEmpower/addCorpEmpower',//新增单位授权书
    'editBookInfo':'/paperless-user/corpEmpower/editCorpEmpower',//修改单位授权书
    'getBookList':'/paperless-user/corpEmpower/getCorpEmpowerPage',//获取授权书列表
    'downLoadBook':'/paperless-user/corpEmpower/downloadZip',//打包下载单位授权书
    'downLoadBook1':'/paperless-user/corpEmpower/excelNotCorpEmpower',//导出未上传授权书列表
    'rejectBookInfo':'/paperless-user/corpEmpower/rejectCorpEmpower',//驳回单位授权书
    'getWorkmanList':'/paperless-user/workMan/getWorkManPage',//作业人员管理分页列表
    //单位授权相关
    'getAuthCorpList':'/paperless-user/corpMcContract/getCorpMcContractPage',//维保公司保养合同分页列表
    'updataAuthCorpItem':'/paperless-user/corpMcContract/addOrUpdate',//修改维保公司保养合同
    'getAuthUserInfo':'/paperless-user/workMan/getWorkManInfo',//获取作业人员管理详细信息
    'addAutnUserInfo':'/paperless-user/workMan/addOrUpdate',//增加或修改作业人员证书
    'addAuthUserFile':'/paperless-user/workMan/upload',//上传证书
    'getCorpAuth':'/paperless-user/corpEmpower/testingYd',//维保公司上传亚德检测
    //亚德数据对接相关
    'getYadeList':'/paperless-user/ydToken/getYdTokenPage',//亚德对接账号信息分页列表
    'addYadeItem':'/paperless-user/ydToken/addYdToken',//新增亚德对接账号信息
    'editYadeItem':'/paperless-user/ydToken/editYdToken',//修改亚德对接账号信息
    'delYadeItem':'/paperless-user/ydToken/removeYdToken',//删除亚德对接账号信息
    //疫情相关
    'getRescueAre':'/paperless-user/epiResPerson/getEpiResPersonByArea',//疫情救援列表
    //通知相关
    'getNoticeList':'/paperless-basedata/notice/getNoticePage',//获取通知列表
    'addNoticeItem':'/paperless-basedata/notice/addNotice',//新增通知
    'editNoticeItem':'/paperless-basedata/notice/editNotice',//修改通知
    'delNoticeItem':'/paperless-basedata/notice/removeNotice',//删除通知
    'sendNoticeItem':'/paperless-basedata/notice/releaseNotice',//发布通知
    'getMyUnreadNotice':'/paperless-basedata/notice/getMyUnreadNotice',//获取首页未读通知
    'readNoticeOne':'/paperless-basedata/notice/readNotice',//阅读通知公告
    'getNoticeInfo':'/paperless-basedata/notice/getNoticeInfo',//获取通知详情
    'getNoticeHistorys':'/paperless-basedata/notice/getHistoryNotice',//历史通知公告列表
    //签到相关
    'getAuthOfficeList':'/paperless-user/authSign/getAuthSignPage',//用户打卡签到分页列表
    //维修相关
    'getRepariList':'/paperless-mc/devRepair/getDevRepairPage',//急修工单分页列表(pc端列表)
    'getRepariItem':'/paperless-mc/devRepair/getRepairInfo',//急修工单详情
}
export default urlList
