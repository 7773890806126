import request from './axios.js'
import qs from 'qs';
/** 
 * 获取类型词典
*/
export function getDic(params) {
    return request({
        face:params.face,
        method: 'get',
        params:params.data
    });
}
export function getModeList(params,face){
    return request({
        face:face,
        method: 'get',
        params
    });
}
export function getData(params){
    return request({
        face:params.face,
        method: 'get',
        params:params.data
    });
}
export function postData(params){
    return request({
        face:params.face,
        method: 'post',
        params:params.data
    });
}