import Vue from 'vue'
import Router from 'vue-router'
import { asyncRouterMap } from './routerConfig'
// hack router push callback
var isBack = false;
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  isBack = false;
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Router.prototype.goBack = function () {
  isBack = true;
  this.back();
}

Vue.use(Router)
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: asyncRouterMap
})

// router.beforeRouteEnter ((to, from, next)=>{
//   if (to.meta.needLogin && to.meta.mustAgent) {
//     next(vm => {
//       vm.needLogin = true
//       vm.mustAgent = true
//     })
//   } else if (to.meta.needLogin || to.meta.mustAgent) {
//     next(vm => { to.meta.needLogin ? vm.needLogin = true : vm.mustAgent = true })
//   } else {
//     next()
//   }
// })
router.afterEach((to,from)=>{
  console.log(to,from)
  var h = window.sessionStorage.getItem('routHistory'),arr=''
  if(h&&h!=''&&h.length>0){
    arr = JSON.parse(h);
  }
  if(!arr||arr==''){
    arr = []
  }
  if(isBack){
    if(arr.length>0){
      var ri = ''
      for (var i = 0; i < arr.length; i++) {
        if(arr[i].path == to.path){
          ri = i
        }
      }
      arr.splice(ri,1);
      window.sessionStorage.setItem('routHistory',JSON.stringify(arr))
    }
  }else{
    if(!to.meta||to.meta.isTab!==false){
      var {name,path} = to;
      if(path!='/login'&&path!='/regist'&&path!='/404'){
        arr.push({name,path})
      }
      var obj = {},arr1 = [];
      for (var i = 0; i < arr.length; i++) {
        if(!obj[arr[i].path]){
          arr1.push(arr[i]);
          obj[arr[i].path]= 1
        }
      }
      arr = arr1
    }
    // History.push({name,path});
  }
  window.sessionStorage.setItem('routHistory',JSON.stringify(arr))
})

export default router
