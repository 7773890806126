<template>
    <div class="inside flex f_start">
        <div class="aslid">
            <!-- <div class="aslid_logo">
                <label class="if-box">
                    <img src="@/assets/images/slider_logo.png"/>
                </label>
            </div> -->
            <el-menu
            :unique-opened="true"
            :default-openeds="openKeys"
            :default-active="menuKey"
            class="el-menu-vertical-demo"
            @select="setRout"
            background-color="#333645"
            text-color="#fff"
            active-text-color="#fff">
            <!-- ffd04b -->
                <template v-for="(item,index) in menus">
                    <el-submenu :key="item.id" :index="item.id.toString()" v-if="item.children.length>0">
                        <template slot="title">
                            <!-- v-html="item.imgSrc" -->
                            <i class="if-box title_icon">
                                <img :src="item.onicon" v-if="item.s == index"/>
                                <img :src="item.icon" v-else/>
                            </i>
                            <span>{{item.name}}</span>
                        </template>
                        <el-menu-item-group>
                            <template v-for="ite in item.children">
                                <el-menu-item :key="ite.id" :index="ite.id.toString()" >{{ite.name}}</el-menu-item>
                            </template>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-menu-item :key="item.id" :index="item.id.toString()" v-else>
                        <!-- v-html="item.imgSrc" -->
                        <i class="if-box title_icon">
                            <img :src="item.onicon" v-if="item.s == index"/>
                            <img :src="item.icon" v-else/>
                        </i>
                        <span slot="title">{{item.name}}</span>
                    </el-menu-item>
                </template>
            </el-menu>
        </div>
        <div class="section" :class="{'hasbd':showBread}">
            <div class="bread_are" v-if="showBread">
                <BreadMap></BreadMap>
            </div>
            <div class="section-panel">
                <!-- <router-view /> -->
                <!-- v-if="$route.meta.keepAlive" -->
                <keep-alive >
                    <router-view>
                    </router-view>
                </keep-alive>

                <!-- <router-view v-if="!$route.meta.keepAlive">
                </router-view> -->
            </div>
        </div>
    </div>
</template>
<script>
// import { Tree } from 'ant-design-vue';
import BreadMap from "@/components/breadMap.vue"
import '@/assets/css/iconfont.css'
export default {
    name:'insideLayout',
    components:{
        BreadMap
    },
    data(){
        return{
            openKeys: [],
            treeData:[],
            replaceFields: {
                children: 'child',
                title: 'name',
            },
            menuType:'',
            menus:[],
            menuKey:'',
            showBread:false,
        }
    },
    computed: {
        isFollow () {
            return this.$store.state.menuType;//需要监听的数据
        }
    },
    watch: {
        isFollow (newVal, oldVal) {
            //do something
            this.menuType = newVal
            // this.getMenuList();
        }
    },
    created(){
        var arr = [];
        let m = JSON.parse(window.localStorage.getItem('user'));
        if(m.menus[0]){
            arr = m.menus[0].children;
        }
        var menuType = window.sessionStorage.getItem('menuIndex');//this.$store.state.menuType;
        arr.forEach(item => {
            if(item.imgSrc&&item.imgSrc!=''){
                item.icon = require('@/assets/images/'+item.imgSrc+'.png')
                item.onicon = require('@/assets/images/'+item.imgSrc+'_on.png')
            }else{
                item.icon = ''
                item.onicon = ''
            }
        });
        this.$set(this,'menus',arr);
        if(!menuType||menuType==''){
            // this.$store.commit('setMenuType', arr[0].id)
            window.sessionStorage.setItem('menuIndex',arr[0].id)
            this.$set(this,'menuType', arr[0].id);
            this.getIndex(arr[0].id);
        }else{
            this.getIndex(menuType);
        }
        // this.getMenuList();
        this.setBread()
    },
    methods: {
        init(){
            var arr = []
            let m = JSON.parse(window.localStorage.getItem('user'));
            if(m.menus[0]){
                arr = m.menus[0].children;
            }
            arr.forEach(item => {
                if(item.imgSrc&&item.imgSrc!=''){
                    item.icon = require('@/assets/images/'+item.imgSrc+'.png')
                }else{
                    item.icon = ''
                }
            });
            this.$set(this,'menus',arr);
            this.findRout(arr[0].url);
            this.setBread()
            // window.sessionStorage.setItem('menuIndex',arr[0].id)
            // this.$set(this,'menuType', arr[0].id);
            // this.getIndex(arr[0].id);
        },
        setRout(key,parms) {
            let arr = JSON.parse(JSON.stringify(this.menus));
            let url = ''
            for (let i = 0; i < arr.length; i++) {
                let item = arr[i];
                if(item.id == key){
                    url = item.url
                }else{
                    if(item.children.length>0){
                        for (let z = 0; z < item.children.length; z++) {
                            let ite = item.children[z];
                            if(ite.id == key){
                                 url = ite.url
                            }
                        }
                    }
                }
            }
            this.$set(this,'openKeys',[])
            window.sessionStorage.setItem('menuIndex',key)
            if(parms&&parms!=''&&parms.isParms){
                window.sessionStorage.setItem('pageParms',JSON.stringify(parms.data))
                // this.$store.commit('setSetParms', true)
                this.$router.push({ path:url})
            }else{
                var isSet = this.$store.state.setParms;
                // if(isSet&&isSet!=''){
                    this.$store.commit('setSetParms', '')
                    window.sessionStorage.removeItem('pageParms')
                // }
                this.$router.push({ path:url})
            }
            this.getIndex(key);
        },
        onSelect(selectedKeys, info) {
            console.log('selected', selectedKeys, info);
        },
        onCheck(checkedKeys, info) {
            console.log('onCheck', checkedKeys, info);
        },
        getMenuList(){
            let m = JSON.parse(window.localStorage.getItem('user')),arr='';
            let b=''
            m.menus[0].children.map((v)=>{
                if(v.id === this.menuType){
                    b = v;
                    this.$set(this,'menus',v.children);
                    this.setRootIndex()
                }
            })
        },
        getIndex(key){
            var arr = JSON.parse(JSON.stringify(this.menus));
            var m = this.deeploopfun(key,arr);
            this.$nextTick(function(){
                if(m.ind||m.inds){
                    if(m.arr1[m.s]&&m.arr1[m.s].childrenButton&&m.arr1[m.s].childrenButton.length>0){
                        this.$store.commit('setmenuPromise', JSON.stringify(m.arr1[m.s].childrenButton))
                    }else{
                        this.$store.commit('setmenuPromise', null)
                    }
                    // console.log(this.$store.state.menuPromise);
                    if(m.ind){
                        this.$set(this,'menuKey',m.ind.toString())
                    }
                    if(m.inds){
                        this.$set(this,'openKeys',[m.inds.toString(),m.ind.toString()])
                    }else{
                        this.$set(this,'openKeys',[])
                    }
                }else{
                    window.sessionStorage.setItem('menuIndex',arr[0].id)
                    this.$set(this,'menuType', arr[0].id);
                    this.getIndex(arr[0].id);
                }
            })
        },
        deeploopfun(key,arr,parent){
            var n = key;
            var arr1 = '',url='';
            var ind=null,inds=null,s=null;
            for (var i = 0; i < arr.length; i++) {
                var item = arr[i];
                if(item.id == n){
                    ind = item.id
                    s = i;
                    arr1 = arr;
                    if(parent){
                        inds = parent.id;
                        arr1 = parent.children;
                        url = parent.url
                    }
                    var tmp = {
                        ind:ind,s:s,arr1:arr1,url:url,inds:inds
                    };
                    return tmp
                }else{
                    if(item.children.length>0){
                        const temp = this.deeploopfun(key,item.children,item)
                        if (temp) return temp
                    }
                }
            }
        },
        setRootIndex(){
            let n = this.$router.history.current.path;
            // this.$set(this,'menuKey',n);
            this.getIndex(n);
            return
            if(z||z === 0){
                this.$set(this,'menuKey',this.menus[z].url);
                this.$store.commit('setmenuPromise', JSON.stringify(this.menus[z].childrenButton))
                // this.setMenu(this.menuList[this.menuIndex])
            }
        },
        findRout(path){
            let arr = JSON.parse(JSON.stringify(this.menus)),id='';
            var arr1 = [],obj={};
            for (var z = 0; z < arr.length; z++) {
                if(!obj[arr[z].id]){
                    arr1.push(arr[z])
                    obj[arr[z].id] = 1;
                    if(arr[z].children.length>0){
                        var arr2 = arr[z].children;
                        for (var s = 0; s < arr2.length; s++) {
                            if(!obj[arr2[s].id]){
                                arr1.push(arr2[s])
                                obj[arr2[s].id] = 1;
                            }
                        }
                    }
                }
            }
            for (var i = 0; i < arr1.length; i++) {
               if(arr1[i].url == path){
                   id = arr1[i].id
               }
            }
            if(id&&id!=''){
                this.setRout(id)
            }
        },
        setBread(){
            var harr = window.sessionStorage.getItem('routHistory')
            if(harr&&harr.length>0){
                this.$set(this,'showBread',true)
            }else{
                this.$set(this,'showBread',false)
            }
        }
    },
    watch: {
      $route(to, from) {
        this.setBread()
      }
    },
}
</script>
<style lang="less" scoped>
.inside{
    width: 100%;
    height: 100%;
    .aslid{
        width: 2.6rem;
        height: 100%;
        overflow-y: auto;
    }
    .section{
        width: calc(100% - 2.6rem);
        height: 100%;
        background-color: #F6F6F6;
        font-size: .18rem;
        padding: .1rem .1rem;
        position: relative;
        // /deep/.el-calendar{
        //     .el-calendar__title{
        //         font-size: 28px;
        //         font-family: "PingFang SC";
        //         font-weight: bold;
        //         line-height: 28px;
        //         color: #000000;
        //     }
        //     .el-calendar-table .el-calendar-day{
        //         height: 55px;
        //         width: 55px;
        //         display: inline-flex;
        //         align-items: center;
        //         justify-content: center;
        //     }
        //     .el-calendar-table thead th{
        //         font-size: 14px;
        //         font-family: "PingFang SC";
        //         font-weight: 400;
        //         line-height: 28px;
        //         color: #999999;
        //     }
        // }
        &.hasbd{
            padding-top: 60px;
            .bread_are{
                position: absolute;
                left: 0;
                right: 0;
                top: 5px;
                margin: auto;
                padding: 0 .1rem;
                background-color: #ffffff;
            }
        }
        .section-panel{
            padding: 10px;
            background-color: #ffffff;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}
/deep/.el-menu{
    height: 100%;
    .el-menu-item.is-active{
        background-color: #0478F1 !important;
        // background: linear-gradient(90deg, #0278F1 0%, #333741 100%);
    }
    .el-menu-item [class^=icon],/deep/.el-submenu__title [class^=icon]{
        margin-right: .05rem;
        width: .24rem;
        text-align: center;
        font-size: .18rem;
        vertical-align: middle;
    }
    .el-menu-item-group>ul>li{
        padding: 0;
        padding-left: 50px !important;
    }
    .el-submenu .el-menu-item{
        min-width: inherit;
    }
    .title_icon{
        width: .18rem;
        margin-right: .05rem;
    }
}

</style>