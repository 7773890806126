// import { basicLayout,insideLayout} from '../layouts/index.js'
import basicLayout from '../layout/basicLayout'
import insideLayout from '../layout/insideLayout'
import userLayout from '../layout/userLayout'
import dataLayout from '../layout/dataLayout'
import webLayout from '../layout/webLayout'
export const asyncRouterMap = [
    {
        path: '/',
        name: '工作台',
        redirect: '/bizmanage/village',
        component: basicLayout,
        children: [
            {
              path: '/sysmanage',
              name: 'sysmanage',
              redirect: '/sysmanage/sysmng',
              component: insideLayout,
              children: [
                      {
                          path: '/sysmanage/sysmng',
                          name: '系统管理',
                          component: () => import(/* webpackChunkName: "fail" */'@/views/sysmanage/sysmng')
                      },
                      {
                        path: '/sysmanage/menumng',
                        name: '菜单管理',
                        component: () => import(/* webpackChunkName: "fail" */'@/views/sysmanage/menumng')
                    },{
                      path: '/sysmanage/rolemng',
                      name: '角色管理',
                      component: () => import(/* webpackChunkName: "fail" */'@/views/sysmanage/rolemng')
                    },{
                      path: '/sysmanage/usermng',
                      name: '用户管理',
                      component: () => import(/* webpackChunkName: "fail" */'@/views/sysmanage/usermng')
                    },{
                      path: '/sysmanage/sysdefList',
                      name: '维保信息',
                      noCache:true,
                      component: () => import(/* webpackChunkName: "fail" */'@/views/sysmanage/sysdef_list')
                    },{
                      path: '/sysmanage/lift_adopt',
                      name: '监管所电梯认领',
                      noCache:true,
                      component: () => import(/* webpackChunkName: "fail" */'@/views/sysmanage/lift_adopt')
                    },{
                      path: '/sysmanage/office',
                      name: '签到管理',
                      noCache:true,
                      component: () => import(/* webpackChunkName: "fail" */'@/views/sysmanage/office')
                    },
                  ]
            },{
            path: '/bizmanage',
            name: 'bizmanage',
            redirect: '/bizmanage/overview',
            component: insideLayout,
            children: [
                {
                    path: '/bizmanage/overview',
                    name: '维保工作台',
                    component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/overview')
                },{
                  path: '/bizmanage/liftmaintain',
                  name: 'liftmaintain',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/liftmaintain')
                },{
                  path: '/bizmanage/mc_list',
                  name: '维保列表',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/mc_list')
                },{
                  path: '/bizmanage/mc_mode',
                  name: '维保模板',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/mc_mode')
                },{
                  path: '/bizmanage/mc_regular',
                  name: '定期维保',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/mc_regular')
                },{
                  path: '/bizmanage/mc_need',
                  name: '按需维保',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/mc_need')
                },{
                  path: '/bizmanage/mc_dispose',
                  name: '按需维保配置',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/mc_dispose')
                },{
                  path: '/bizmanage/trans',
                  name: '维保转单',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/trans')
                },
                {
                  path: '/village/:villageId*',
                  component: () => import('@/views/bizmanage/village'),
                  name: 'village',
                  meta: {
                    title: '我的小区',
                  }
                },{
                  path: '/bizmanage/lift',
                  name: '我的电梯',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/lift'),
                },
                {
                  path: '/bizmanage/village',
                  name: '我的小区',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/village'),
                  // meta: {
                  //   keepAlive: true
                  // }
                },{
                  path: '/bizmanage/mc_exam',
                  name: '维保审核',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/mc_exam')
                },{
                  path: '/bizmanage/mc_over_exam',
                  name: '超期维保审核',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/mc_over_exam')
                },{
                  path: '/bizmanage/mc_needSys',
                  name: '开通按需维保',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/mc_needSys')
                },{
                  path: '/bizmanage/mc_stop',
                  name: '停梯维保',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/mc_stop')
                },{
                  path: '/bizmanage/mc_delay',
                  name: '维保延期',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/mc_delay')
                },{
                  path: '/bizmanage/mc_delayYade',
                  name: '延期排期恢复',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/bizmanage/mc_delayYade')
                },
              ]
            },{
              path: '/datamanage',
              name: 'datamanage',
              redirect: '/datamanage/overview',
              component: dataLayout,
              children: [
                  {
                      path: '/datamanage/overview',
                      name: 'doverview',
                      component: () => import(/* webpackChunkName: "fail" */'@/views/datamanage/overview')
                  },
                  {
                    path: '/datamanage/archives',
                    name: 'archives',
                    component: () => import(/* webpackChunkName: "fail" */'@/views/datamanage/archives')
                  },
                  {
                    path: '/datamanage/syndata',
                    name: 'syndata',
                    component: () => import(/* webpackChunkName: "fail" */'@/views/datamanage/syndata')
                  },
                  {
                    path: '/datamanage/alarm',
                    name: 'alarm',
                    component: () => import(/* webpackChunkName: "fail" */'@/views/datamanage/alarm')
                  },
                  {
                    path: '/datamanage/callhistory',
                    name: 'callhistory',
                    component: () => import(/* webpackChunkName: "fail" */'@/views/datamanage/callhistory')
                  },
                  {
                    path: '/datamanage/mtnrecord',
                    name: 'mtnrecord',
                    component: () => import(/* webpackChunkName: "fail" */'@/views/datamanage/maintainrecord')
                  }
              ]
            },{
              path:'/devops',
              name:'devops',
              redirect: '/devops/rule',
              component: insideLayout,
              children:[
                {
                  path: '/devops/rule',
                  name: '运维用户管理',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/rule')
                },{
                  path: '/devops/company',
                  name: '公司管理',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/company'),
                  // meta: {
                  //   keepAlive: true
                  // }
                },{
                  path: '/devops/rolemng',
                  name: '运维角色管理',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/rolemng')
                },{
                  path: '/devops/mc_report',
                  name: '申请记录',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/mc_report')
                },{
                  path: '/devops/mc_needMode',
                  name: '按需维保模板',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/mc_needMode')
                },{
                  path: '/devops/mc_channel',
                  name: '渠道管理',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/mc_channel')
                },{
                  path: '/devops/company_mng',
                  name: '公司设置',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/company_mng')
                },{
                  path: '/devops/login_report',
                  name: '登录日志',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/login_report')
                },{
                  path: '/devops/operate_report',
                  name: '操作日志',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/operate_report')
                },{
                  path: '/devops/certificate_mng',
                  name: '授权书管理',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/certificate_mng')
                },{
                  path: '/devops/property_respect',
                  name: '使用单位维护',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/property_respect')
                },{
                  path: '/devops/worker_list',
                  name: '人员信息维护',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/sysmanage/worker_list')
                },{
                  path: '/devops/yadeMng',
                  name: '亚德对接账号管理',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/yadeMng')
                },{
                  path: '/devops/notice',
                  name: '通知管理',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/notice')
                },{
                  path: '/devops/repari',
                  name: '报修记录',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/repari')
                },{
                  path: '/devops/repari_hist',
                  name: '维修历史',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/devops/repari_hist')
                }
              ]
            },{
              path:'/rescue',
              name:'rescue',
              redirect: '/rescue/index',
              component: insideLayout,
              children:[
                {
                  path: '/rescue/index',
                  name: '当前救援',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/rescue/index')
                },{
                  path: '/rescue/hastory',
                  name: '历史救援',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/rescue/hastory')
                },{
                  path: '/rescue/newWaring',
                  name: '实时告警',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/rescue/newWaring')
                },{
                  path: '/rescue/reportWaring',
                  name: '告警记录',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/rescue/reportWaring')
                },{
                  path: '/rescue/waringCensus',
                  name: '告警统计',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/rescue/waringCensus')
                },
              ]
            },{
              path:'/report',
              name:'report',
              redirect: '/report/census',
              component: insideLayout,
              children:[
                {
                  path: '/report/census',
                  name: '电梯统计',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/report/census')
                },{
                  path: '/report/score',
                  name: '电梯评分',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/report/score')
                },{
                  path: '/report/insure',
                  name: '保险超期',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/report/safeOver')
                },{
                  path: '/report/Inspection',
                  name: '年检超期',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/report/yearly')
                },{
                  path: '/report/liftPool',
                  name: '分类汇总',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/report/liftPool')
                },{
                  path: '/report/mcCensus',
                  name: '维保统计',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/report/mcCensus')
                },
              ]
            },{
              path:'/task',
              name:'task',
              redirect: '/task/taskMng',
              component: insideLayout,
              children:[
                {
                  path: '/task/taskMng',
                  name: '任务管理',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/task/taskMng')
                },{
                  path: '/task/taskReport',
                  name: '任务日志',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/task/taskReport')
                },
              ]
            },{
              path:'/terminal',
              name:'terminal',
              redirect: '/terminal/uphold',
              component: insideLayout,
              children:[
                {
                  path: '/terminal/uphold',
                  name: '终端维护',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/terminal/uphold')
                },{
                  path: '/terminal/iccm_mng',
                  name: '终端管理',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/terminal/iccm_mng')
                }
              ]
            },{
              path:'/96333mng',
              name:'96333mng',
              redirect: '/96333mng/attestation',
              component: insideLayout,
              children:[
                {
                  path: '/96333mng/attestation',
                  name: '小区认证码查询',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/96333mng/attestation')
                },{
                  path: '/96333mng/attestArea',
                  name: '区县认证码查询',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/96333mng/attestArea')
                },{
                  path: '/96333mng/attestBind',
                  name: '认证绑定情况',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/96333mng/attestBind')
                },{
                  path: '/96333mng/attestLifts',
                  name: '电梯认领查询',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/96333mng/attestLifts')
                },{
                  path: '/96333mng/attestYade',
                  name: '亚德数据查询',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/96333mng/attestYade')
                },
              ]
            },
            {
              path: '/workOrder',
              name: 'workOrder',
              redirect: '/workOrder/queryOrder',
              component: insideLayout,
              children: [{
                path: '/workOrder/queryOrder',
                name: '工单查询',
                component: () => import( /* webpackChunkName: "fail" */ '@/views/workOrder/queryOrder')
              },{
                path: '/workOrder/orderCensus',
                name: '工单统计',
                component: () => import( /* webpackChunkName: "fail" */ '@/views/workOrder/orderCensus')
              }]
            },
            {
              path: '/call',
              name: 'call',
              redirect: '/call/phoneAlarm',
              component: insideLayout,
              children: [{
                path: '/call/phoneAlarm',
                name: '语音呼叫',
                component: () => import( /* webpackChunkName: "fail" */ '@/views/call/phoneAlarm')
              },{
                  path: '/call/videoAlarm',
                  name: '视频呼叫',
                  component: () => import(/* webpackChunkName: "fail" */'@/views/call/videoAlarm')
                }]
            }
        ]
    },
    {
      path: '/login',
      component: userLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "fail" */'@/views/login/login')
        },

        {
          path: 'recover',
          name: 'recover',
          component: undefined
        }
      ]
    },
    {
      path: '/regist',
      component: webLayout,
      children: [
        {
          path: '/regist',
          name: 'regist',
          component: () => import(/* webpackChunkName: "fail" */'@/views/login/regist')
        },{
          path: '/exportMod',
          name: 'exportMod',
          component: () => import(/* webpackChunkName: "fail" */'@/views/login/exportMod')
        },{
          path: '/rescueMod',
          name: 'rescueMod',
          component: () => import(/* webpackChunkName: "fail" */'@/views/login/rescue')
        },{
          path: '/noticeInfo',
          name: 'noticeInfo',
          component: () => import(/* webpackChunkName: "fail" */'@/views/login/noticeInfo')
        },{
          path: '/repariInfo',
          name: 'repariInfo',
          component: () => import(/* webpackChunkName: "fail" */'@/views/login/repariMode')
        },{
          path: '/test',
          name: 'testPage',
          component: () => import(/* webpackChunkName: "fail" */'@/views/login/test')
        },
      ]
    },{
      path: '/dmsSys',
      component: basicLayout,
      redirect: '/dmsSys/index',
      children: [
        {
          meta:{
            isTab:false
          },
          path: '/dmsSys/index',
          name: 'dmsSysIndex',
          component: () => import(/* webpackChunkName: "fail" */'@/views/dmsSys/index')
        },{
          meta:{
            isTab:false
          },
          path: '/dmsSys/form',
          name: 'dmsSysForm',
          component: () => import(/* webpackChunkName: "fail" */'@/views/dmsSys/form')
        }
      ]
    },{
      path: '/404',
      name:'ex404',
      component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
    }
]
