<template>
    <div class="main f-box" >
        <!-- :class="{'f_end':islogin,'f_center':!islogin}" -->
        <label class="logo">
            <img src="@/assets/images/login_logo.png"/>
        </label>
        <div class="login_are">
            <!-- <label class="if-box label_icon">
                <img src="@/assets/images/login_fix_bg.png"/>
            </label> -->
            <router-view/>
        </div>
        <div class="navi">
			<div style="margin-top:10px">智慧电梯综合应用平台</div>
			<div>
        	<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010502000935">
		 		<img src="@/assets/images/police.png" style="height:12px;">渝公网安备 50010502000935号		
	 		</a>
	 		</div>
        	<div>©2016-2019 sptesyun.com 版权所有 <a target="_blank" href="http://beian.miit.gov.cn/">渝ICP备16011984号-1</a></div>
        </div>
    </div>
</template>
<script>
export default {
    name:'userLayout',
    data(){
        return{
            // islogin:false,
        }
    },
    // created(){
    //     let r = this.$router.history.current.path
    //     if(r == '/login'){
    //         this.$set(this,'islogin',true);
    //     }
    // }
}
</script>
<style lang="less" scoped>
    .main{
        // position: fixed;
        width: 100%;
        height: 100%;
        // top: 0;
        // left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(../assets/images/login_bg.png);
        padding-bottom: 90px;
        position: relative;
        // padding-right: 10%;
        .logo{
            position: absolute;
            top: .6rem;
            left: .84rem;
            height: .4rem;
        }
        .login_are{
            position: relative;
            padding-left: 6rem;
            background-position: left;
            background-repeat: no-repeat;
            background-size: 6rem 100%;
            background-image: url(../assets/images/login_fix_bg.png);
            // .label_icon{
            //     width: 8.4rem;
            //     height: 100%;
            //     position: absolute;
            //     top: 0;
            //     bottom: 0;
            //     margin: auto;
            //     left: 0;
            // }
        }
        .navi{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 90px;
            // background: rgba(0,0,0,.2);
            font-family: 'Microsoft YaHei';
            outline: none;
            font-size: 12px;
            color: #999;
            text-align: center;
            a{
                color: #999;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
</style>