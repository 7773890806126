import { render, staticRenderFns } from "./dataLayout.vue?vue&type=template&id=c4276c10&scoped=true"
import script from "./dataLayout.vue?vue&type=script&lang=js"
export * from "./dataLayout.vue?vue&type=script&lang=js"
import style0 from "./dataLayout.vue?vue&type=style&index=0&id=c4276c10&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4276c10",
  null
  
)

export default component.exports